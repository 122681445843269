import React from 'react';

import { EType } from '../../../../repositories/journal/entities/journal/JournalAttributesSchema';
import { Details as DetailsComponent, DetailsSeparator } from '../../../Details';
import { DatePublished, DateTimePublished } from '../../../DatePublished';
import { NumViews } from '../NumViews';
import { RegionName } from '../RegionName';
import { BlogLink } from '../BlogLink';
import { PostCommentsCount } from '../../../PostCommentsCount';
import { IPost } from '../../Post';
import * as s from './Details.css';

type TDetails = Pick<
  IPost,
  | 'authorPostsCount'
  | 'authorId'
  | 'commentsCount'
  | 'canSeeComments'
  | 'datePublished'
  | 'numViews'
  | 'postRegionName'
  | 'postCommentsUrl'
  | 'type'
>;

/**
 * Компонент информации о посте
 */
export const Details = ({
  authorPostsCount,
  authorId,
  commentsCount,
  datePublished,
  canSeeComments,
  numViews,
  postRegionName,
  postCommentsUrl,
  type,
}: TDetails) => {
  return (
    <div className={s['wrapper']}>
      <DetailsComponent size="m">
        {type === EType.News && (
          <>
            <DateTimePublished>{datePublished}</DateTimePublished>
            <NumViews>{numViews}</NumViews>
            <DetailsSeparator />
            {canSeeComments && <PostCommentsCount link={postCommentsUrl} count={commentsCount || 0} />}
          </>
        )}

        {type === EType.Questions && (
          <>
            <DatePublished>{datePublished}</DatePublished>
            <RegionName>{postRegionName}</RegionName>
            <NumViews>{numViews}</NumViews>
            <DetailsSeparator />
            {canSeeComments && <PostCommentsCount link={postCommentsUrl} count={commentsCount || 0} />}
          </>
        )}

        {type === EType.Blogs && (
          <>
            <DatePublished>{datePublished}</DatePublished>
            <NumViews>{numViews}</NumViews>
            <BlogLink authorId={authorId}>{authorPostsCount}</BlogLink>
            <DetailsSeparator />
            {canSeeComments && <PostCommentsCount link={postCommentsUrl} count={commentsCount || 0} />}
          </>
        )}

        {type === EType.Articles && (
          <>
            <DatePublished>{datePublished}</DatePublished>
            <NumViews>{numViews}</NumViews>
            <DetailsSeparator />
            {canSeeComments && <PostCommentsCount link={postCommentsUrl} count={commentsCount || 0} />}
          </>
        )}
      </DetailsComponent>
    </div>
  );
};
