import React from 'react';

import { SidebarCard } from '../SidebarCard';
import { Skeleton } from '../Skeleton';
import { SidebarDiscussionsItemSkeleton } from './components/SidebarDiscussionsItem';
import { POST_CARD_DISCUSSIONS_REQUEST_LIMIT } from '../../constants';
import * as s from './SidebarDiscussions.css';

/**
 * Скелетон ленты обсуждений в сайдбаре
 */
export const SidebarDiscussionsSkeleton = () => (
  <SidebarCard>
    <Skeleton width={'71%'} height={24} />

    <div className={s['items-wrapper']}>
      {[...Array(POST_CARD_DISCUSSIONS_REQUEST_LIMIT)].map((item, index) => (
        <SidebarDiscussionsItemSkeleton key={index} />
      ))}
    </div>
  </SidebarCard>
);
