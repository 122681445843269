/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAddLikeModel,
  IAddLikeRequest,
  IMappers,
  IAddLikeResponse,
  IAddLikeResponseError,
  TAddLikeResponse,
} from './types';

const defaultConfig: TAddLikeModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'journal',
  pathApi: '/v1/add-like/',
  hostType: 'api',
} as TAddLikeModel;

function createAddLikeModel(parameters: IAddLikeRequest): TAddLikeModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAddLikeOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAddLikeRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAddLike<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAddLikeOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TAddLikeResponse> {
  const { response, statusCode } = await httpApi.fetch(createAddLikeModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAddLikeResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAddLikeResponseError);
    }
  }

  return { response, statusCode } as TAddLikeResponse;
}

export { defaultConfig, createAddLikeModel, fetchAddLike };
