import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { render } from 'react-dom';

import { CollapserUncontrolled } from '../../components/Collapser';
import { IExtractAndReplaceCollapsersItem } from './extractAndReplaceCollapsers';
import { PostContentCollapserLayout } from '../../components/PostContentCollapserLayout';

/**
 * Рендерит и управляет коллапсерами в посте
 */
export const useContentCollapsers = (
  extractedCollapsersItems: Pick<IExtractAndReplaceCollapsersItem, 'id' | 'title' | 'content'>[],
  onToggleOpened?: () => void,
) => {
  const handleToggle = useCallback(
    isOpened => {
      if (!isOpened && onToggleOpened) {
        onToggleOpened();
      }
    },
    [onToggleOpened],
  );

  useEffect(() => {
    extractedCollapsersItems.forEach(collapser => {
      render(
        <PostContentCollapserLayout>
          {/* eslint-disable react/no-danger */}
          <CollapserUncontrolled
            title={<div dangerouslySetInnerHTML={{ __html: collapser.title }} />}
            onToggle={handleToggle}
          >
            <div dangerouslySetInnerHTML={{ __html: collapser.content }} />
          </CollapserUncontrolled>
        </PostContentCollapserLayout>,
        document.getElementById(collapser.id),
      );
    });
  }, [extractedCollapsersItems, handleToggle]);
};
