import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SkeletonPost, Post } from '../../components/Post';
import { findPostRegionName } from './utils';
import { buildPostUrl, parsePostCardPath, useEffectOnce, useGetPost, usePreparedContent } from '../../utils';
import { selectPost } from '../../selectors/post';
import { selectUser } from '../../selectors/user';
import { selectCurrentRegion } from '../../selectors/geo/currentRegion';
import { selectRegions } from '../../selectors/regions/selectRegions';
import { getIsPostCommentsUserSubscribed, getPost, getPostLikeCounts, resetPost } from '../../actions/post';
import { POST_COMMENTS_ID, PostCardPrefixAsPostType } from '../../constants';
import { PERMISSIONS } from '../../../app/permissions/permissionsNew';
import { ERequestStatus } from '../../types/requestStatus';
import { TThunkDispatch } from '../../types/redux';
import { EType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';

export const PostContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const { id: qsPostId, prefix } = parsePostCardPath(pathname);
  const post = useSelector(selectPost);
  const user = useSelector(selectUser);
  const currentRegion = useSelector(selectCurrentRegion);
  const { items: dictionaryRegions } = useSelector(selectRegions);
  const postType = prefix && PostCardPrefixAsPostType[prefix];
  const preparedContent = usePreparedContent();

  useGetPost(
    () => {
      const run = async () => {
        await dispatch(getPost({ id: qsPostId }));
        await dispatch(getIsPostCommentsUserSubscribed({ id: qsPostId }));

        if (postType && (EType.Articles === postType || EType.News === postType)) {
          await dispatch(getPostLikeCounts({ postId: qsPostId, postType }));
        }
      };

      void run();
    },
    { dependencyNames: ['pathname'] },
  );

  useEffectOnce(() => {
    return () => {
      dispatch(resetPost());
    };
  });

  if (post.status === ERequestStatus.Loading) {
    return <SkeletonPost />;
  }

  if (!('id' in post)) {
    return null;
  }

  const {
    id,
    attributes: {
      commentsCount,
      datePublish,
      image,
      numViews,
      subtitle,
      title,
      type,
      regions,
      slug,
      updatedAt,
      authorObject,
      authorId,
    },
  } = post;
  const permissions = PERMISSIONS[type];

  return (
    <Post
      authorPostsCount={undefined}
      authorId={authorId || undefined}
      authorName={authorObject?.fullName || undefined}
      commentsCount={commentsCount}
      content={preparedContent}
      dateModified={updatedAt}
      datePublished={datePublish}
      image={image}
      numViews={numViews}
      postCommentsUrl={buildPostUrl({ type, slug, id, anchor: POST_COMMENTS_ID })}
      postRegionName={findPostRegionName({
        globalRegionId: currentRegion.id,
        postRegionIds: regions || [],
        dictionaryRegions,
      })}
      subtitle={subtitle}
      title={title}
      type={type}
      canSeeComments={permissions.canSeeComments(user, post)}
    />
  );
};
