import React from 'react';

import { RoundedSkeleton, Skeleton } from '../../../Skeleton';
import * as s from './SidebarDiscussionsItem.css';

/**
 * Скелетон элемента ленты обсуждений в сайдбаре
 */
export const SidebarDiscussionsItemSkeleton = () => {
  return (
    <div className={s['wrapper']}>
      <Skeleton height={16} margin={'0 0 8px 0'} />
      <Skeleton width={'83.44%'} height={16} margin={'0 0 12px 0'} />
      <Skeleton height={88} margin={'0 0 8px 0'} borderRadius={'12px 12px 12px 0'} />

      <div className={s['skeleton-avatars']}>
        {[...Array(3)].map((_, index) => (
          <RoundedSkeleton key={index} size={30} />
        ))}
      </div>
    </div>
  );
};
