import React from 'react';
import { Like16 as IconSocialLikeOn16, Button, UIHeading4 } from '@cian/ui-kit';

import { IconSocialDislikeOff16 } from '../Icons';
import { numberWithDelimiter } from '../../utils';
import * as s from './PostLikes.css';

interface IPostLikes {
  onLikeClick(e: React.MouseEvent): void;
  onDislikeClick(e: React.MouseEvent): void;
  isDisabled?: boolean;
  isUserLiked?: boolean;
  likesCount?: number;
  dislikesCount?: number;
}

/**
 * Блок лайков для поста
 */
export const PostLikes = ({
  likesCount,
  dislikesCount,
  isDisabled,
  isUserLiked,
  onLikeClick,
  onDislikeClick,
}: IPostLikes) => {
  return (
    <div className={s['wrapper']}>
      <UIHeading4>Понравилась статья?</UIHeading4>

      <div className={`${s['buttons']} ${isUserLiked ? s['_readonly'] : ''}`}>
        <Button
          beforeIcon={<IconSocialLikeOn16 color={isDisabled ? 'gray20_100' : 'white_100'} />}
          theme={'fill_primary'}
          fullWidth
          disabled={isDisabled}
          onClick={onLikeClick}
        >
          {likesCount ? numberWithDelimiter(likesCount) : 'Да'}
        </Button>

        <Button
          beforeIcon={<IconSocialDislikeOff16 color={isDisabled ? 'gray20_100' : 'primary_100'} />}
          theme={'stroke_primary'}
          fullWidth
          disabled={isDisabled}
          onClick={onDislikeClick}
        >
          {dislikesCount ? numberWithDelimiter(dislikesCount) : 'Нет'}
        </Button>
      </div>
    </div>
  );
};
