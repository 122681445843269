import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, useDeviceType } from '@cian/ui-kit';

import { SearchListingHeaderContainer } from '../ListingHeader';
import { PaginationContainer } from '../Pagination';
import {
  buildPostUrl,
  preparePageNumber,
  prepareQSParam,
  prepareUserAvatar,
  useGetListing,
  useTimeoutEffect,
  unescapeHtml,
} from '../../utils';
import { CIAN_URL, SEARCH_LIST_REQUEST_LIMIT } from '../../constants';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { getSearchingResults, resetSearchingResults } from '../../actions/searchingResults';
import { selectCurrentPageMeta, selectPageMetaItems } from '../../selectors/pagesMeta';
import { EType as EPagesMetaType } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import {
  CardListingItem,
  ListingItem,
  SkeletonCardListingItem,
  SkeletonListingItem,
} from '../../components/ListingItem';
import { ERequestStatus } from '../../types/requestStatus';
import { selectFavoriteListingType } from '../../selectors/settings/selectFavoriteListingType';
import { EListingTypes } from '../../types/listing';
import { EType as EPostType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { EQType, EType } from '../../repositories/journal/v1/search';
import { NothingFound } from '../../components/NotFound';
import { selectSearchingResults } from '../../selectors/searchingResults';
import { IJournalAttributesRubricSchema } from '../../repositories/journal/entities/journal/JournalAttributesRubricSchema';
import { IArticlesNewsListAttributesV2Schema } from '../../repositories/journal/entities/journal/ArticlesNewsListAttributesV2Schema';
import { UserCard } from '../../components/UserCard';
import { prepareSubtitle } from './helpers';
import * as s from './Listings.css';

/**
 * Листинг страницы поиска
 */
export const SearchList = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsPage = preparePageNumber(searchParams.get('page') as string);
  const qsSearch = prepareQSParam(searchParams.get('search'));
  const qsTag = prepareQSParam(searchParams.get('tag'));
  const appendType = useSelector(selectListingAppendType);
  const favoriteListingType = useSelector(selectFavoriteListingType);
  const { type: pageType, pathname: currentPageTypePathname } = useSelector((state: IApplicationState) =>
    selectCurrentPageMeta(state, pathname),
  );
  const pagesMeta = useSelector(selectPageMetaItems);
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const [IsShownAsList, setIsShownAsList] = useState(false);
  const { status: searchingStatus, items: results } = useSelector(selectSearchingResults);
  const [isSkeletonShown, setIsSkeletonShown] = useState(
    searchingStatus === ERequestStatus.Loading && appendType === 'set',
  );
  const mapPageTypeToSearchingType = {
    [EPagesMetaType.Articles]: EType.Articles,
    [EPagesMetaType.Blogs]: EType.Blogs,
    [EPagesMetaType.News]: EType.News,
    [EPagesMetaType.Questions]: EType.Questions,
    [EPagesMetaType.MainPage]: EType.All,
    [EPagesMetaType.Documents]: EType.All,
    [EPagesMetaType.Page]: EType.All,
    [EPagesMetaType.PostCard]: EType.All,
  };
  const searchingType = mapPageTypeToSearchingType[pageType];

  /** Если не нашел нужный тип, выкидывает на дефолтный */
  useEffect(() => {
    if (
      ![
        EPagesMetaType.MainPage,
        EPagesMetaType.Articles,
        EPagesMetaType.News,
        EPagesMetaType.Questions,
        EPagesMetaType.Blogs,
      ].includes(pageType)
    ) {
      history.replace(currentPageTypePathname);
    }
  }, [currentPageTypePathname, history, pageType]);

  /** Забирает статьи в стор и трекает показ листинга */
  useGetListing(
    () => {
      dispatch(
        getSearchingResults({
          q: qsSearch || qsTag || undefined,
          qType: qsSearch ? EQType.Journal : qsTag ? EQType.Tags : EQType.Journal,
          offset: (qsPage - 1) * SEARCH_LIST_REQUEST_LIMIT,
          limit: SEARCH_LIST_REQUEST_LIMIT,
          type: searchingType,
          setType: appendType,
        }),
      ).finally();
    },
    { dependencyNames: ['page', 'search', 'tag', 'pathname', 'deviceType'] },
  );

  /** При выходе сбрасывает список в сторе */
  useEffect(() => {
    return () => {
      dispatch(resetSearchingResults());
    };
  }, [dispatch]);

  /** Устанавливает вид листинга: карточки или список */
  useEffect(() => {
    setIsShownAsList(favoriteListingType === EListingTypes.List && deviceType !== 'phone');
  }, [favoriteListingType, deviceType]);

  /** Создает небольшую задержку перед скрытием скелетона, когда элементов не найдено */
  useTimeoutEffect(() => {
    setIsSkeletonShown(searchingStatus === ERequestStatus.Loading && appendType === 'set');
  }, [!isSkeletonShown || results.length ? 0 : 500]);

  /**
   * При клике по плашке статьи роутит на соответствующую страницу статьи
   * При клике по шильдику рубрики в плашке статьи роутит на соответствующую рубрику
   */
  const handleClick = useCallback(
    (e, url: string) => {
      e.preventDefault();
      history.push(url);
    },
    [history],
  );

  /**
   * Выбирает первую рубрику для статьи.
   * Ищет нужный pageType для других типов материалов
   * и строит структуру с названием и ссылкой
   */
  const getPostRubricOrCategory = (
    postType: EPostType,
    rubrics?: IJournalAttributesRubricSchema[],
    category?: IArticlesNewsListAttributesV2Schema['category'],
  ) => {
    if (postType === EPostType.Articles) {
      return rubrics?.length ? rubrics[0] : undefined;
    }

    const pageMeta =
      category &&
      pagesMeta.find(
        pageMeta => pageMeta.type === (postType as unknown as EPagesMetaType) && pageMeta.category === category,
      );

    return pageMeta ? { name: pageMeta.title, url: pageMeta.pathname } : undefined;
  };

  /**
   * При клике по карточке пользователя отправляет на соответствующую страницу профиля
   */
  const handleUserCardClick = (e: React.MouseEvent, url: string) => {
    e.preventDefault();
    window.location.href = url;
  };

  return (
    <>
      <SearchListingHeaderContainer />

      <div className={IsShownAsList ? s['list-wrapper'] : s['cards-wrapper']}>
        {isSkeletonShown &&
          [...Array(6)].map((_, index) =>
            IsShownAsList ? (
              <div key={index} className={s['list-item-wrapper']}>
                <SkeletonListingItem />
              </div>
            ) : (
              <Col key={index} xs={12} m={6} l={4}>
                <SkeletonCardListingItem />
              </Col>
            ),
          )}

        {((searchingStatus === ERequestStatus.Succeed && appendType === 'set') || appendType === 'append') &&
          results.map(
            (
              {
                id,
                attributes: {
                  image,
                  title,
                  subtitle,
                  datePublish,
                  commentsCount,
                  rubrics,
                  category,
                  slug,
                  type: postType,
                  authorObject,
                  noIndex,
                  disableComments,
                  likesCount,
                },
              },
              index,
            ) => {
              return IsShownAsList ? (
                <div key={index} className={s['list-item-wrapper']}>
                  <ListingItem
                    title={unescapeHtml(title)}
                    subtitle={prepareSubtitle(subtitle, postType)}
                    image={image}
                    rubric={getPostRubricOrCategory(postType, rubrics, category)}
                    datePublish={datePublish}
                    numComments={disableComments ? undefined : commentsCount}
                    numLikes={likesCount}
                    url={buildPostUrl({ type: postType, slug, id })}
                    noIndex={Boolean(noIndex)}
                    type={postType}
                    onRubricButtonClick={handleClick}
                    onClick={e => handleClick(e, buildPostUrl({ type: postType, slug, id }))}
                  >
                    {authorObject && authorObject?.userId && (
                      <UserCard
                        imageSrc={prepareUserAvatar(authorObject?.avatar || undefined)}
                        name={
                          authorObject.fullName ||
                          authorObject?.companyName ||
                          (authorObject?.userId ? `ID: ${authorObject.userId}` : 'Аноним')
                        }
                        description={(authorObject.fullName && authorObject?.companyName) || undefined}
                        isTrusted={authorObject?.userTrust || undefined}
                        url={authorObject?.specialistLink ? `${CIAN_URL}${authorObject.specialistLink}` : undefined}
                        onClick={e =>
                          authorObject?.specialistLink &&
                          handleUserCardClick(e, `${CIAN_URL}${authorObject.specialistLink}`)
                        }
                      />
                    )}
                  </ListingItem>
                </div>
              ) : (
                <Col key={index} xs={12} m={6} l={4}>
                  <CardListingItem
                    title={unescapeHtml(title)}
                    subtitle={prepareSubtitle(subtitle, postType, Boolean(image))}
                    height={isPhone() ? undefined : 400}
                    image={[EPostType.Blogs, EPostType.Questions].includes(postType) ? undefined : image}
                    rubric={getPostRubricOrCategory(postType, rubrics, category)}
                    datePublish={datePublish}
                    numComments={disableComments ? undefined : commentsCount}
                    numLikes={likesCount}
                    url={buildPostUrl({ type: postType, slug, id })}
                    noIndex={Boolean(noIndex)}
                    type={postType}
                    onRubricButtonClick={handleClick}
                    onClick={e => handleClick(e, buildPostUrl({ type: postType, slug, id }))}
                  >
                    {authorObject && authorObject?.userId && (
                      <UserCard
                        imageSrc={prepareUserAvatar(authorObject?.avatar || undefined)}
                        name={
                          authorObject.fullName ||
                          authorObject?.companyName ||
                          (authorObject?.userId ? `ID: ${authorObject.userId}` : 'Аноним')
                        }
                        description={(authorObject.fullName && authorObject?.companyName) || undefined}
                        isTrusted={authorObject?.userTrust || undefined}
                        url={authorObject?.specialistLink ? `${CIAN_URL}${authorObject.specialistLink}` : undefined}
                        onClick={e =>
                          authorObject?.specialistLink &&
                          handleUserCardClick(e, `${CIAN_URL}${authorObject.specialistLink}`)
                        }
                      />
                    )}
                  </CardListingItem>
                </Col>
              );
            },
          )}

        {(searchingStatus === ERequestStatus.Failed ||
          (searchingStatus === ERequestStatus.Succeed && !results.length)) && (
          <NothingFound pathname={currentPageTypePathname} />
        )}
      </div>

      {searchingStatus === ERequestStatus.Succeed && Boolean(results.length) && (
        <div className={s['controls-wrapper']}>
          <PaginationContainer />
        </div>
      )}
    </>
  );
};
