import { RefObject, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useIsMounted } from '../../../../utils';
import { POST_COMMENTS_ID } from '../../../../constants';

/**
 * Плавно скроллит до комментариев и удаляет якорь
 */
export const useScrollIntoPostComments = <T extends HTMLElement>(
  postCommentsRef: RefObject<T>,
  delayMs: number = 1000,
) => {
  const { hash, search } = useLocation();
  const history = useHistory();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!postCommentsRef?.current || hash !== `#${POST_COMMENTS_ID}`) {
      return;
    }

    clearTimeout(timerRef.current);
    postCommentsRef.current.scrollIntoView({ behavior: 'smooth' });

    timerRef.current = setTimeout(() => {
      if (!isMounted()) {
        return;
      }

      history.replace(`?${new URLSearchParams(search).toString()}`);
    }, delayMs);
  }, [delayMs, hash, history, isMounted, postCommentsRef, search]);
};
