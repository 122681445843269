import React from 'react';

import { IUserCardParams } from './UserCard';
import { RoundedSkeleton, Skeleton } from '../Skeleton';
import s from './UserCard.css';

type TUserCardSkeleton = Pick<IUserCardParams, 'size'> & { children?: React.ReactNode };

/**
 * Скелетон карточки пользователя
 */
export const UserCardSkeleton = ({ size = 'M', children }: TUserCardSkeleton) => {
  const sizes = {
    XL: 64,
    L: 48,
    M: 40,
    S: 30,
  };

  return (
    <div className={s['wrapper']}>
      <div className={s['image']}>
        <RoundedSkeleton size={sizes[size]} />
      </div>

      <div className={s['content']}>{children || <Skeleton width={100} />}</div>
    </div>
  );
};
