import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IGetLikesResponse } from '../../../repositories/journal/v1/get-likes';

export enum EGetPostLikeCountsActionType {
  Succeed = 'post/getPostLikeCounts/succeed',
}

export interface IGetPostLikeCountsSucceedParams extends Pick<IGetLikesResponse, 'likesCount' | 'dislikesCount'> {
  userLikeValue: IGetLikesResponse['userLike'];
}

export type TGetPostLikeCountsSucceed = ITypedReduxAction<
  EGetPostLikeCountsActionType.Succeed,
  IGetPostLikeCountsSucceedParams
>;

export type TGetPostLikeCounts = TGetPostLikeCountsSucceed;
