import { IPostCard } from '../../../shared/types/post_card';
import { IList } from '../../../shared/types/list_item_props';
import { getTimeDiff, getTimeDiffNew } from './';

export function isPublishedLessThenMonthAgo(model: IPostCard | IList): boolean {
  return Math.ceil(getTimeDiff(model) / (1000 * 3600 * 24)) <= 31;
}

export function isPublishedLessThenMonthAgoNew(date: Date): boolean {
  return Math.ceil(getTimeDiffNew(date) / (1000 * 3600 * 24)) <= 31;
}
