import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useRequestAuthentication } from '../../../../utils';
import { addLike, IAddLike } from '../../../../actions/post';
import { TThunkDispatch } from '../../../../types/redux';

export function useLikes() {
  const dispatch = useDispatch<TThunkDispatch>();
  const checkAuth = useRequestAuthentication();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Отправляет лайк/дизлайк на бэк или отправляет на авторизацию
   */
  const handleClick = useCallback(
    async ({ postId, postType, likeType }: IAddLike) => {
      setIsLoading(true);

      if (checkAuth()) {
        await dispatch(
          addLike({
            postId,
            postType,
            likeType,
          }),
        );
      }

      setIsLoading(false);
    },
    [checkAuth, dispatch],
  );

  return {
    handleLikeClick: handleClick,
    isLikesLoading: isLoading,
  };
}
