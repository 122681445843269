import { ERequestStatus } from '../../types/requestStatus';
import { EGetSeoUrlsActionType, EResetSeoUrlsActionType, TSeoUrlsActions } from '../../actions/seoUrls';
import { TSeoUrlsState } from '../../types/seo_urls';

export const defaultState: TSeoUrlsState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const seoUrlsReducer = (state = defaultState, action: TSeoUrlsActions) => {
  switch (action.type) {
    case EGetSeoUrlsActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetSeoUrlsActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetSeoUrlsActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetSeoUrlsActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};
