/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TAddLikeModel = IModel<IAddLikeRequest, TAddLikeResponse>;

export interface IAddLikeRequest {
  /** Тип сущности - статья, новость, коммент и т.д. **/
  contentTypeModel: EContentTypeModel;
  /** ID прикрепленного опроса **/
  objectPk: number;
  /** 1 или -1 **/
  vote: number;
}

export type TAddLikeResponse = IAddLikeResponse200 | IAddLikeResponse400;

export interface IAddLikeResponse200 extends IModelResponse<IAddLikeResponse> {
  statusCode: 200;
}

export interface IAddLikeResponse400 extends IModelResponse<IAddLikeResponseError> {
  statusCode: 400;
}

export interface IAddLikeResponse {
  /** Число дизлайков **/
  dislikesCount: number;
  /** Число лайков **/
  likesCount: number;
  /** Как проголосовал пользователь: -1, 0 или 1 **/
  userLike: number;
}

export interface IAddLikeResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IAddLikeResponse | IAddLikeResponseError;

export enum EContentTypeModel {
  /** Статьи **/
  Articles = 'articles',
  /** Новости **/
  News = 'news',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IAddLikeResponse): TResponse200;
  400(response: IAddLikeResponseError): TResponse400;
}
