import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthorArticles } from '../../components/AuthorArticles';
import { ApplicationContext } from '../../utils';
import { getAuthorArticles, getAuthorArticlesReset } from '../../actions/authorArticles';
import { selectAuthorArticles } from '../../selectors/authorArticles';
import { selectCurrentPost } from '../../selectors/posts/selectCurrentPost';
import { TThunkDispatch } from '../../types/redux';

/** Список статей автора */
export const AuthorArticlesContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { items: articles } = useSelector(selectAuthorArticles);
  const {
    attributes: { seoTitle: titleAsAuthorName },
  } = useSelector(selectCurrentPost);
  const {
    custom: { authorArticlesLimit },
  } = useContext(ApplicationContext);
  const [authorName, setAuthorName] = useState<string | undefined>();

  /**
   * Запрашивает список статей автора, если еще ни разу не запрашивали
   */
  useEffect(() => {
    if (!titleAsAuthorName || titleAsAuthorName === authorName || !authorArticlesLimit) {
      return;
    }

    void dispatch(getAuthorArticles({ authorName: titleAsAuthorName, limit: authorArticlesLimit }));
  }, [dispatch, authorArticlesLimit, titleAsAuthorName, authorName]);

  /**
   * Запоминает изменение автора
   */
  useEffect(() => {
    if (titleAsAuthorName !== authorName) {
      setAuthorName(titleAsAuthorName);
    }
  }, [dispatch, titleAsAuthorName, authorName]);

  /**
   * Сбрасывает статьи автора при размонтировании
   */
  useEffect(() => {
    return () => {
      dispatch(getAuthorArticlesReset());
    };
  }, [dispatch]);

  if (!articles?.length) {
    return null;
  }

  return <AuthorArticles items={articles} />;
};
