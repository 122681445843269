import React from 'react';

import { Skeleton } from '../../../../components/Skeleton';
import * as s from './TabsContentSkeleton.css';

/**
 * Скелетон для табов авторов и тегов
 */
export const TabsContentSkeleton = () => {
  return (
    <div className={s['wrapper']}>
      {[150, 200, 100].map((width, index) => (
        <Skeleton key={index} width={width} />
      ))}
    </div>
  );
};
