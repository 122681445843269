import React, { useCallback } from 'react';
import { Avatar, UIHeading4, Like16 as IconLike16 } from '@cian/ui-kit';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { TDiscussion } from '../../../../types/discussions';
import { buildPostUrl } from '../../../../utils';
import { IconWithCount } from '../../../IconWithText';
import { PostCommentsCount } from '../../../PostCommentsCount';
import * as s from './SidebarDiscussionsItem.css';

/**
 * Элемент ленты обсуждений в сайдбаре
 */
export const SidebarDiscussionsItem = ({
  content: { title, slug, type, commentsCount, likesCount, id: postId },
  comment,
  id: commentId,
  commentUsers,
}: TDiscussion) => {
  const link = `${buildPostUrl({ type, slug, id: postId })}`;
  const history = useHistory();
  const { search } = useLocation();

  const handleCommentClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      const searchParams = new URLSearchParams(search);
      searchParams.set('comment-id', String(commentId));
      history.push(`${link}?${searchParams.toString()}`);
    },
    [commentId, history, link, search],
  );

  return (
    <div className={s['wrapper']}>
      <UIHeading4>
        <Link to={link} className={s['post-link']}>
          {title}
        </Link>
      </UIHeading4>

      <Link to={link} className={s['comment-link']} onClick={handleCommentClick}>
        <div className={s['comment-text']}>{comment}</div>
      </Link>

      <div className={s['footnote']} onClick={handleCommentClick}>
        <div className={s['avatars']}>
          {commentUsers.map(({ avatar, userId }) => (
            <Avatar key={userId} src={avatar} type="user" size={30} />
          ))}
        </div>

        {commentsCount ? <PostCommentsCount count={commentsCount} link={link} /> : null}
        {likesCount ? <IconWithCount count={likesCount} icon={<IconLike16 />} /> : null}
      </div>
    </div>
  );
};
