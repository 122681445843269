import { TUserState } from '../../shared/types/user';
import { isAdminOrModerator, isPublishedLessThenMonthAgoNew } from '../utils';
import { TPostState } from '../../shared/types/post';
import { EType } from '../../shared/repositories/journal/entities/journal/JournalAttributesSchema';

type TPermissionFunctionNames =
  | 'canEdit'
  | 'canDelete'
  | 'canSeeCaptions'
  | 'canComment'
  | 'canSeeComments'
  | 'canLikes'
  | 'canComplaint'
  | 'canSubscribeComments';

export type TPermission = {
  [key in TPermissionFunctionNames]: (user: TUserState, model: TPostState) => boolean;
};

export type TPermissions = { [key in EType]: TPermission };

export const DEFAULT_PERMISSION: TPermission = {
  canEdit: () => false,
  canDelete: () => false,
  canSeeCaptions: () => false,
  canComment: () => true,
  canSeeComments: () => true,
  canLikes: () => true,
  canComplaint: () => false,
  canSubscribeComments: () => true,
};

export const PERMISSIONS: TPermissions = {
  [EType.Blogs]: {
    ...DEFAULT_PERMISSION,

    canEdit: (user, post) => {
      if (!user.isAuthenticated || !('id' in post)) {
        return false;
      }

      const {
        attributes: { authorObject },
      } = post;

      return Boolean(authorObject && user.userId === authorObject.userId);
    },

    canSeeCaptions: (user, post) => {
      if (!user.isAuthenticated || !('id' in post)) {
        return false;
      }

      const {
        attributes: { authorObject },
      } = post;

      return isAdminOrModerator(user) || Boolean(authorObject && user.userId === authorObject.userId);
    },

    canLikes: (user, post) => {
      if (!user.isAuthenticated || !('id' in post)) {
        return false;
      }

      const {
        attributes: { authorObject },
      } = post;

      return Boolean(authorObject && user.userId !== authorObject.userId && !user.isBlock);
    },

    canComplaint: user => user.isAuthenticated,
  },

  [EType.Questions]: {
    ...DEFAULT_PERMISSION,

    canSeeCaptions: (user, post) => {
      if (!user.isAuthenticated || !('id' in post)) {
        return false;
      }

      const {
        attributes: { authorObject },
      } = post;

      return isAdminOrModerator(user) || Boolean(authorObject && user.userId === authorObject.userId);
    },

    canComment: (user, post) => {
      if (!('id' in post)) {
        return false;
      }

      const {
        attributes: { datePublish },
      } = post;

      return isPublishedLessThenMonthAgoNew(new Date(datePublish));
    },

    canLikes: (user, post) => {
      if (!('id' in post)) {
        return false;
      }

      const {
        attributes: { datePublish },
      } = post;

      return isPublishedLessThenMonthAgoNew(new Date(datePublish)) && !user.isBlock;
    },

    canComplaint: user => user.isAuthenticated,

    canSubscribeComments: (user, post) => {
      if (!('id' in post)) {
        return false;
      }

      const {
        attributes: { datePublish },
      } = post;

      return isPublishedLessThenMonthAgoNew(new Date(datePublish));
    },
  },

  [EType.Articles]: {
    ...DEFAULT_PERMISSION,

    canComment: (user, post) => {
      if (!('id' in post)) {
        return false;
      }

      const {
        attributes: { disableComments },
      } = post;

      return !disableComments;
    },

    canSeeComments: (user, post) => {
      if (!('id' in post)) {
        return false;
      }

      const {
        attributes: { disableComments },
      } = post;

      return !disableComments;
    },
  },

  [EType.News]: {
    ...DEFAULT_PERMISSION,

    canComment: (user, post) => {
      if (!('id' in post)) {
        return false;
      }

      const {
        attributes: { disableComments },
      } = post;

      return !disableComments;
    },

    canSeeComments: (user, post) => {
      if (!('id' in post)) {
        return false;
      }

      const {
        attributes: { disableComments },
      } = post;

      return !disableComments;
    },
  },

  [EType.Quizzes]: {
    ...DEFAULT_PERMISSION,
  },
};
