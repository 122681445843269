import React from 'react';

import { SidebarCard } from '../SidebarCard';
import { Skeleton } from '../Skeleton';
import { SidebarNewsItemSkeleton } from './components/SidebarNewsItem';
import { POST_CARD_DISCUSSIONS_REQUEST_LIMIT } from '../../constants';
import * as s from './SidebarNews.css';

/**
 * Скелетон ленты обсуждений в сайдбаре
 */
export const SidebarNewsSkeleton = () => (
  <SidebarCard>
    <Skeleton width={'71%'} height={24} />

    <div className={s['items-wrapper']}>
      {[...Array(POST_CARD_DISCUSSIONS_REQUEST_LIMIT)].map((item, index) => (
        <SidebarNewsItemSkeleton key={index} />
      ))}
    </div>
  </SidebarCard>
);
