import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { preparePageNumber } from '../preparePageNumber';
import { prepareCommentsOrdering, prepareListingOrdering } from '../prepareOrdering';
import { isAuthorBlog } from '../isAuthorBlog';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../types/redux';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { prepareQSParam } from '../prepareQSParam';

export type TDependencyName =
  | 'page'
  | 'ordering'
  | 'commentsOrdering'
  | 'search'
  | 'tag'
  | 'with-tag'
  | 'rubricId'
  | 'category'
  | 'pathname'
  | 'isAuthorBlog'
  | 'pageType'
  | 'deviceType'
  | 'authorId'
  | 'commentUserId'
  | 'commentId';

/**
 * Генерирует строку из чувствительных значений для запроса
 * к апи-ручкам листингов с целью блокировки повторных вызовов
 */
export const useSensitiveParams = (dependencyNames: TDependencyName[] = ['page']) => {
  const { search, pathname } = useLocation();
  const {
    rubricId,
    category,
    type: pageType,
  } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const deviceType = useDeviceType();

  const mkKey = useCallback(
    () =>
      dependencyNames
        .reduce((acc, dependencyName) => {
          if (dependencyName === 'page') {
            acc.push(preparePageNumber(searchParams.get('page') || ''));
          } else if (dependencyName === 'ordering') {
            acc.push(prepareListingOrdering(searchParams.get('ordering') || '') || '');
          } else if (dependencyName === 'commentsOrdering') {
            acc.push(prepareCommentsOrdering(searchParams.get('ordering') || '') || '');
          } else if (dependencyName === 'search') {
            acc.push(prepareQSParam(searchParams.get('search')));
          } else if (dependencyName === 'tag') {
            acc.push(prepareQSParam(searchParams.get('tag')));
          } else if (dependencyName === 'with-tag') {
            acc.push(prepareQSParam(searchParams.get('with-tag')));
          } else if (dependencyName === 'rubricId') {
            acc.push(rubricId || '');
          } else if (dependencyName === 'category') {
            acc.push(category || '');
          } else if (dependencyName === 'pathname') {
            acc.push(pathname);
          } else if (dependencyName === 'isAuthorBlog') {
            acc.push(isAuthorBlog(pathname));
          } else if (dependencyName === 'pageType') {
            acc.push(pageType);
          } else if (dependencyName === 'deviceType') {
            acc.push(deviceType);
          } else if (dependencyName === 'authorId') {
            acc.push(prepareQSParam(searchParams.get('author_id')));
          } else if (dependencyName === 'commentUserId') {
            acc.push(prepareQSParam(searchParams.get('comment_user_id')));
          } else if (dependencyName === 'commentId') {
            acc.push(prepareQSParam(searchParams.get('comment-id') || ''));
          }

          return acc;
        }, [] as (string | number | boolean)[])
        .join('|'),
    [category, dependencyNames, deviceType, pageType, pathname, rubricId, searchParams],
  );
  const [key, setKey] = useState(mkKey());

  useEffect(() => {
    const keyNew = mkKey();

    if (keyNew === key) {
      return;
    }

    setKey(keyNew);
  }, [key, mkKey]);

  return key;
};
