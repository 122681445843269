import * as React from 'react';

import { IUser } from '../../types/user';
import { unescapeHtml } from '../../utils';

import s from './comments.css';

export const avatar = (user: IUser) => {
  const avatar_html = user.avatar ? (
    <div
      style={{
        backgroundImage: `url(${unescapeHtml(user.avatar)})`,
        backgroundSize: 'contain',
      }}
      className={s['comments__item_photo']}
    />
  ) : (
    <div className={s['comments__form_photo-default']} />
  );

  if (user.specialistLink) {
    return <a href={`${user.specialistLink}`}>{avatar_html}</a>;
  }

  return avatar_html;
};
