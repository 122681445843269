/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TGetLikesModel = IModel<IGetLikesRequest, TGetLikesResponse>;

export interface IGetLikesRequest {
  /** Тип сущности - статья, новость, коммент и т.д. **/
  contentTypeModel?: EContentTypeModel | null;
  /** ID прикрепленного опроса **/
  objectPk?: number | null;
}

export type TGetLikesResponse = IGetLikesResponse200 | IGetLikesResponse400;

export interface IGetLikesResponse200 extends IModelResponse<IGetLikesResponse> {
  statusCode: 200;
}

export interface IGetLikesResponse400 extends IModelResponse<IGetLikesResponseError> {
  statusCode: 400;
}

export interface IGetLikesResponse {
  /** Число дизлайков **/
  dislikesCount: number;
  /** Число лайков **/
  likesCount: number;
  /** Как проголосовал пользователь: -1, 0 или 1 **/
  userLike: number;
}

export interface IGetLikesResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetLikesResponse | IGetLikesResponseError;

export enum EContentTypeModel {
  /** Статьи **/
  Articles = 'articles',
  /** Новости **/
  News = 'news',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetLikesResponse): TResponse200;
  400(response: IGetLikesResponseError): TResponse400;
}
