import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Button, Search16 as IconSearch16, Spinner } from '@cian/ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CancellationToken } from '@cian/http-api';

import { IApplicationState, TThunkDispatch } from '../../../types/redux';
import { ReactPortal } from '../../../components/ReactPortal';
import { QuickSearchingModal } from '../../../components/QuickSearchingModal';
import { ApplicationContext, buildPostUrl, debounce, prepareQSParam } from '../../../utils';
import { defaultState, ESearchingActionType, searchingReducer } from './helpers';
import { getQuickSearchingResults, TGetQuickSearchingResultsCallbackAction } from '../../../actions/searchingResults';
import {
  QS_SEARCH_SUPPORTING_PAGE_PATHS,
  QS_TAG_SUPPORTING_PAGE_PATHS,
  QUICK_SEARCH_LIST_REQUEST_LIMIT,
} from '../../../constants';
import { IQuickSearchingResults } from '../../../types/quickSearchingResults';
import { ERequestStatus } from '../../../types/requestStatus';
import { mkQuickSearchingResults } from '../../../__stubs__/mocks/quickSearchingResults';
import { selectSearchingModalStatus } from '../../../selectors/settings/selectSearchingModalStatus';
import { toggleSearchingModal } from '../../../actions/settings';
import { QuickSearchingItem, QuickSearchingTagItem } from '../../../components/QuickSearchingItem';
import { EType } from '../../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { NothingFound } from '../../../components/NotFound';
import { ERegionsStatus } from '../../../types/regions';
import * as s from './SearchButtonContainer.css';
import { selectCurrentPageMeta, selectPageMetaItems } from '../../../selectors/pagesMeta';

/**
 * Кнопка вызова модалки с формой поиска
 */
export const SearchButtonContainer = () => {
  const thunkDispatch = useDispatch<TThunkDispatch>();
  const [{ status, items }, dispatch] = useReducer(searchingReducer, defaultState);
  const isModalOpened = useSelector(selectSearchingModalStatus);
  const context = useContext(ApplicationContext);
  const { search, pathname } = useLocation();
  const { pathname: currentPageMetaPathname } = useSelector((state: IApplicationState) =>
    selectCurrentPageMeta(state, pathname),
  );
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsSearch =
    QS_SEARCH_SUPPORTING_PAGE_PATHS.includes(currentPageMetaPathname) && prepareQSParam(searchParams.get('search'));
  const qsTag =
    QS_TAG_SUPPORTING_PAGE_PATHS.includes(currentPageMetaPathname) && prepareQSParam(searchParams.get('tag'));
  const cancellationTokenRef = useRef<CancellationToken | null>(null);
  const history = useHistory();
  const [inputValue, setInputValue] = useState(qsSearch || qsTag || '');
  const pagesMeta = useSelector(selectPageMetaItems);

  /**
   * Callback для ручки поиска
   * Запускает экшены локального стора для разных состояний запроса
   */
  const updateState = (action: TGetQuickSearchingResultsCallbackAction, data?: IQuickSearchingResults) => {
    switch (action) {
      case ERequestStatus.Loading:
        return dispatch({ type: ESearchingActionType.Loading });

      case ERequestStatus.Succeed:
        return dispatch({
          type: ESearchingActionType.Succeed,
          payload: data || mkQuickSearchingResults({ items: [] }),
        });

      case ERequestStatus.Failed:
        return dispatch({ type: ESearchingActionType.Failed });
    }
  };

  /**
   * Запрашивает результаты поиска
   * Прерывает предыдущую запуск поиска (опционально)
   * Сбрасывает локальный стор при закрытии модалки
   * Сбрасывает результаты поиска при пустом запросе
   */
  useEffect(() => {
    const preparedValue = prepareQSParam(inputValue);

    // Всегда сбрасываем токен, если он существует
    if (cancellationTokenRef?.current) {
      cancellationTokenRef.current.cancel();
      cancellationTokenRef.current = null;
    }

    // При закрытии окна сбрасываем локальный стор и начальное значение инпута
    if (!isModalOpened) {
      dispatch({ type: ESearchingActionType.Reset });
      setInputValue(qsSearch || qsTag || '');

      return;
    }

    // При пустом инпуте сбрасываем локальный стор
    if (!preparedValue) {
      dispatch({ type: ESearchingActionType.Reset });

      return;
    }

    cancellationTokenRef.current = new CancellationToken();

    thunkDispatch(
      getQuickSearchingResults({
        q: preparedValue,
        limit: QUICK_SEARCH_LIST_REQUEST_LIMIT,
        cancellationToken: cancellationTokenRef.current,
        callback: updateState,
      }),
    ).finally();
  }, [isModalOpened, inputValue, thunkDispatch, qsSearch, qsTag]);

  /**
   * При изменении инпута сохраняем запрос
   */
  const saveSearchingPhrase = (value: string) => {
    setInputValue(value);
  };

  // Немного тормозим обработку инпута
  const searchResultsDebounced = debounce(saveSearchingPhrase, 20);

  /** Переключает модалку поиска */
  const toggleModal = useCallback(
    (value?: boolean) => {
      thunkDispatch(toggleSearchingModal(value));
    },
    [thunkDispatch],
  );

  /**
   * Закрывает модалку и роутит на основную страницу поиска
   * (если нажата кнопка submit или Enter)
   */
  const handleSubmit = useCallback(() => {
    toggleModal(false);

    history.push(`/magazine/?search=${encodeURIComponent(inputValue)}`);
  }, [history, inputValue, toggleModal]);

  /**
   * Роутит на основную страницу поиска по тегам
   * при клике на найденный тег
   */
  const handleTagItemClick = useCallback(
    (name: string) => {
      toggleModal(false);

      history.push(`/magazine/?tag=${encodeURIComponent(name)}`);
    },
    [history, toggleModal],
  );

  return (
    <>
      <div className={s['wrapper']} onClick={() => toggleModal()}>
        <IconSearch16 />
        <span className={`${s['searching-query']} ${qsSearch || qsTag ? s['_active'] : ''}`}>
          {qsSearch || qsTag || 'Поиск по журналу'}
        </span>
      </div>

      <ReactPortal wrapperId={'searching-modal'} parent={context.config.getStrict<string>('projectName')}>
        <QuickSearchingModal
          isOpened={isModalOpened}
          inputValue={inputValue}
          onClose={() => toggleModal(false)}
          onSubmit={handleSubmit}
          onChange={searchResultsDebounced}
        >
          <>
            {status === ERequestStatus.Loading && (
              <div className={s['spinner-wrapper']}>
                <Spinner size={48} />
              </div>
            )}

            <div className={s['searching-results']}>
              {status === ERequestStatus.Succeed &&
                Boolean(items.length) &&
                items.map(
                  (
                    { id, type, attributes: { name, title, datePublish, slug, objectsCount, rubrics, category } },
                    index,
                  ) => {
                    const categories = [];
                    if (category) {
                      const pageMeta = pagesMeta.find(
                        pageMeta => pageMeta.type === type && pageMeta.category === category,
                      );

                      if (pageMeta) {
                        categories.push(pageMeta.title);

                        if (pageMeta.title !== pageMeta.breadCrumbName) {
                          categories.push(pageMeta.breadCrumbName);
                        }
                      }
                    }

                    switch (type) {
                      case 'tags':
                        return (
                          <QuickSearchingTagItem
                            key={`quick-searching-item-${index}`}
                            name={name || ''}
                            count={objectsCount || 0}
                            onClick={handleTagItemClick}
                          />
                        );

                      default:
                        return (
                          <QuickSearchingItem
                            key={`quick-searching-item-${index}`}
                            name={title || ''}
                            date={datePublish || undefined}
                            rubrics={
                              rubrics?.length
                                ? rubrics.map(rubric => rubric.name)
                                : categories?.length
                                ? categories
                                : undefined
                            }
                            url={buildPostUrl({ type: type as EType, slug, id })}
                            onClick={() => toggleModal(false)}
                          />
                        );
                    }
                  },
                )}
            </div>

            {[ERequestStatus.Succeed, ERegionsStatus.Failed].includes(status) && !items.length && (
              <div className={s['nothing-found-wrapper']}>
                <NothingFound />
              </div>
            )}

            {status === ERequestStatus.Succeed && Boolean(items.length) && (
              <div className={s['submit-wrapper']}>
                <Button theme={'fill_secondary'} onClick={handleSubmit}>
                  Смотреть все результаты
                </Button>
              </div>
            )}
          </>
        </QuickSearchingModal>
      </ReactPortal>
    </>
  );
};
