import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SidebarDiscussions, SidebarDiscussionsSkeleton } from '../../components/SidebarDiscussions';
import { parsePostCardPath } from '../../utils';
import { selectDiscussions } from '../../selectors/discussions';
import { getDiscussions, resetDiscussions } from '../../actions/discussions';
import { POST_CARD_DISCUSSIONS_REQUEST_LIMIT } from '../../constants';
import { ERequestStatus } from '../../types/requestStatus';
import { TThunkDispatch } from '../../types/redux';

/**
 * Контейнер списка дискуссий
 */
export const SidebarDiscussionsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const { items: discussions, status: discussionsStatus } = useSelector(selectDiscussions);
  const [prevPathname, setPrevPathname] = useState(pathname);
  const { id: qsPostId } = parsePostCardPath(pathname);

  /**
   * Запрашивает список, если еще ни разу не запрашивал
   * или сменился чувствительный параметр
   */
  useEffect(() => {
    if (discussionsStatus !== ERequestStatus.Initial && pathname === prevPathname) {
      return;
    }

    void dispatch(getDiscussions({ excludePostIds: [qsPostId], limit: POST_CARD_DISCUSSIONS_REQUEST_LIMIT }));
    setPrevPathname(pathname);
  }, [dispatch, discussionsStatus, pathname, prevPathname, qsPostId]);

  /**
   * При выходе сбрасывает список
   */
  useEffect(() => {
    return () => {
      dispatch(resetDiscussions());
    };
  }, [dispatch]);

  /**
   * Скелетон только для первичной загрузки
   */
  if (discussionsStatus === ERequestStatus.Loading && !discussions.length) {
    return <SidebarDiscussionsSkeleton />;
  }

  /**
   * Для всех статусов с пустым списком (кроме Loading)
   */
  if (!discussions.length) {
    return null;
  }

  return (
    <SidebarDiscussions
      items={discussions}
      isLoading={discussionsStatus === ERequestStatus.Loading && Boolean(discussions.length)}
    />
  );
};
