import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { hydrate } from 'react-dom';

import { PostContentRecommendedOffersSlider } from '../../components/PostContentRecommendedOffersSlider';
import { selectPostContentRecommendedOfferGroups } from '../../selectors/postContentRecommendedOffers';
import { IRecommendationOfferSchema } from '../../repositories/journal/entities/recommendations/RecommendationOfferSchema';
import { IPostContentRecommendedOffers } from '../../types/postContentRecommendedOffers';

export interface IHandleSliderClickParams
  extends Pick<IRecommendationOfferSchema, 'offerId' | 'url' | 'fromDeveloper'> {
  index: number;
  position: IPostContentRecommendedOffers['position'];
}

interface IUseContentRecommendedOffersParams {
  onClick?(params: IHandleSliderClickParams): void;
}

const CONTENT_WIDTH = '250px';
const CONTENT_HEIGHT = '314px';
const SCROLL_LENGTH = 256;

/**
 * Рендерит и управляет рекомендованными объявлениями
 */
export const useContentRecommendedOffers = (
  html: string,
  onClick?: IUseContentRecommendedOffersParams['onClick'],
): string => {
  const groups = useSelector(selectPostContentRecommendedOfferGroups);

  /** Отправляет клик по баннеру */
  const handleSliderClick = useCallback(
    (params: IHandleSliderClickParams) => {
      if (!onClick) {
        return;
      }

      onClick(params);
    },
    [onClick],
  );

  /** Рендерит рекомендашки для CSR */
  useEffect(() => {
    groups.forEach(group => {
      const root = document.getElementById(group.id);

      if (root) {
        hydrate(
          <PostContentRecommendedOffersSlider
            group={group}
            offerWidth={CONTENT_WIDTH}
            offerHeight={CONTENT_HEIGHT}
            scrollLength={SCROLL_LENGTH}
            onOfferClick={({ offerId, url, fromDeveloper }, index) =>
              handleSliderClick({ offerId, url, index, position: group.position, fromDeveloper })
            }
          />,
          root,
        );
      }
    });
  }, [groups, handleSliderClick]);

  /** Рендерит рекомендашки для SSR */
  if (typeof window === 'undefined') {
    return groups.reduce((acc, group) => {
      return acc.replace(
        `<div id="${group.id}"></div>`,
        `<div id="${group.id}">${renderToString(
          <PostContentRecommendedOffersSlider
            group={group}
            offerWidth={CONTENT_WIDTH}
            offerHeight={CONTENT_HEIGHT}
            scrollLength={SCROLL_LENGTH}
            onOfferClick={({ offerId, url, fromDeveloper }, index) =>
              handleSliderClick({ offerId, url, index, position: group.position, fromDeveloper })
            }
          />,
        )}</div>`,
      );
    }, html);
  }

  return html;
};
