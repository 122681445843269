import { ca } from '@cian/analytics';

export function trackDocumentLinkClick(fileName: string) {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine',
    action: 'download_doc',
    fileName,
  });
}
