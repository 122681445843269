import React from 'react';
import { Image } from '@cian/ui-kit';

import { makeHTMLFromString } from '../../utils';
import * as s from './PostBlockquote.css';

export type TPostBlockquoteTheme = 'blue' | 'pink' | 'green' | 'lightblue' | 'gray';

export interface IPostBlockquote {
  image?: string;
  text?: string;
  theme?: TPostBlockquoteTheme;
  children?: React.ReactNode;
}

/**
 * Цитата для поста
 */
export const PostBlockquote = ({ image, text, theme = 'gray', children }: IPostBlockquote) => {
  if (!image && !text && !children) {
    return null;
  }

  return (
    <div className={`${s['wrapper']} ${s[`_${theme}`]}`}>
      {image && (
        <div className={s['image-wrapper']}>
          <Image src={image} borderRadius={'4px'} />
        </div>
      )}

      <div className={s['content']}>
        {/* eslint-disable-next-line react/no-danger */}
        {text && <div className={s['text']} dangerouslySetInnerHTML={{ __html: makeHTMLFromString(text).__html }} />}
        {children}
      </div>
    </div>
  );
};
