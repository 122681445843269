import React from 'react';

import { Skeleton } from '../Skeleton';
import * as s from './AuthorArticles.css';

/**
 * Скелетон списка статей автора
 */
export const AuthorArticlesSkeleton = () => (
  <div className={s['wrapper']}>
    <div className={s['title']}>
      <Skeleton width={200} height={20} />
    </div>

    <ul className={s['list-wrapper']}>
      {[1, 2, 3, 4, 5].map((item, index) => (
        <li key={index} className={s['item-wrapper']}>
          <span className={s['link']}>
            <Skeleton />
            <Skeleton width={'40%'} />
          </span>
        </li>
      ))}
    </ul>
  </div>
);
