import React from 'react';

import * as s from './PostParentsLayout.css';

/**
 * Декоратор для компонентов PostParentsContainer и PostAdvertisingLabelContainer
 */
export const PostParentsLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);
