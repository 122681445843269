import { TThunkAction } from '../../../types/redux';
import {
  EContentTypeModel,
  fetchGetLikes,
  TGetLikesResponse,
  IGetLikesRequest,
} from '../../../repositories/journal/v1/get-likes';
import { throwError } from '../../../utils';
import { getPostLikeCountsSucceed } from './getPostLikeCountsSucceed';
import { EType } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';

export interface IGetPostLikeCounts {
  postId: IGetLikesRequest['objectPk'];
  postType: EType.Articles | EType.News;
}

export const getPostLikeCounts = ({ postId, postType }: IGetPostLikeCounts): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    try {
      const { response, statusCode }: TGetLikesResponse = await fetchGetLikes({
        httpApi,
        parameters: {
          contentTypeModel: postType === EType.Articles ? EContentTypeModel.Articles : EContentTypeModel.News,
          objectPk: postId,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: response.message, statusCode, domain: 'actions.post.getPostLikeCounts' });

        return false;
      }

      const { dislikesCount, likesCount, userLike } = response;

      dispatch(
        getPostLikeCountsSucceed({
          dislikesCount,
          likesCount,
          userLikeValue: userLike,
        }),
      );

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.post.getPostLikeCounts',
      });

      return false;
    }
  };
};
