import React from 'react';
import { Success16 as IconFunctionalSuccess16 } from '@cian/ui-kit';

import * as s from './AdvertisingLabel.css';

/**
 * Лейбл "Партнёрский материал"
 */
export const AdvertisingLabel = () => (
  <div className={s['wrapper']}>
    <IconFunctionalSuccess16 />
    Партнёрский материал
  </div>
);
