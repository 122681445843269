import React, { useCallback, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Count, ICount } from './components/Count';
import { POST_COMMENTS_ID } from '../../constants';
import * as s from './PostCommentsCount.css';

interface IPostCommentsCount extends ICount {
  link?: string;
}

/**
 * Количество комментариев поста
 */
export const PostCommentsCount = ({ count, link }: IPostCommentsCount) => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      history.push(`?${searchParams.toString()}#${POST_COMMENTS_ID}`);
    },
    [history, searchParams],
  );

  if (link) {
    return (
      <Link className={s['wrapper']} to={link} onClick={handleClick}>
        <Count count={count} />
      </Link>
    );
  }

  return <Count count={count} />;
};
