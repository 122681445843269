import * as React from 'react';

import { HonestWork, PopupDirection } from '@cian/honestwork-component';

import { IUser } from '../../types/user';
import { unescapeHtml } from '../../utils';

import s from './author_block.css';

interface IProps {
  author?: IUser;
  small?: boolean;
  containerClass?: string;
  authorClass?: string;
}

export class AuthorBlock extends React.Component<IProps> {
  public render() {
    const author = this.getAuthor();

    return (
      <div
        className={`
            ${s['advice-list-article-author']}
            ${this.props.containerClass || ''}
          `}
      >
        <div
          className={`
              ${s['advice-list-article-author_avatar']}
              ${this.props.small ? s['advice-list-article-author_avatar-small'] : ''}
            `}
        >
          <div className={s['advice-list-article-author_avatar-outer']}>
            <div className={s['advice-list-article-author_avatar-inner']}>
              {author.specialistLink ? (
                <a href={`${author.specialistLink}`}>{this.renderAvatar()}</a>
              ) : (
                this.renderAvatar()
              )}
            </div>
          </div>
        </div>
        <div
          className={`
              ${s['advice-list-article-author_info']}
              ${this.props.small ? s['small'] : ''}
              ${
                !author.companyName || (author.companyName && !author.fullName)
                  ? s['advice-list-article-author_info-empty-company']
                  : ''
              }
              ${this.props.small ? '' : s['advice-list-article-author_info-mobile']}
            `}
        >
          <div>
            {this.userName()}
            {this.renderBadges()}
          </div>
          {(!this.props.small || !author.companyName) && (
            <span className={s['advice-list-article-author_info-descr']}>
              {author.fullName === '' && author.companyName !== '' ? '' : author.companyName || ''}
            </span>
          )}
        </div>
      </div>
    );
  }

  private getAuthor() {
    return (
      this.props.author || {
        avatar: undefined,
        fullName: undefined,
        userTrust: false,
        isProfi: false,
        attributes: {},
        companyName: '',
        specialistLink: undefined,
      }
    );
  }

  private renderAvatar() {
    const author = this.getAuthor();
    let avatarBlock = (
      <div
        className={`
          ${s['advice-list-article-author_avatar-img']}
          ${s['advice-list-article-author_avatar-img-default']}
        `}
      />
    );

    if (author.avatar) {
      avatarBlock = (
        <div
          className={s['advice-list-article-author_avatar-img']}
          style={{
            backgroundImage: `url(${unescapeHtml(author.avatar)})`,
          }}
        />
      );
    }

    return avatarBlock;
  }

  private userName() {
    const { author } = this.props;
    const name =
      author && author.userId > 0 ? author.fullName || author.companyName || `ID: ${author.userId}` : 'Аноним';

    if (author) {
      if (author.specialistLink) {
        return (
          <a
            className={`
              ${s['advice-list-article-author_info-name']}
              ${this.props.small ? s['small'] : ''}
              ${this.props.authorClass}
            `}
            href={`${author.specialistLink}`}
          >
            {name}
          </a>
        );
      }
    }

    return (
      <h5
        className={`
            ${s['advice-list-article-author_info-name']}
            ${this.props.small ? s['small'] : ''}
          `}
      >
        {name}
      </h5>
    );
  }

  private renderBadges = () => {
    const author = this.getAuthor();

    return (
      <div
        className={`
            ${s['advice-list-article-author_rating']}
            ${this.props.small ? s['small'] : ''}
            ${!author.companyName ? s['advice-list-article-author_rating-empty-company'] : ''}
          `}
      >
        {author.userTrust && <HonestWork className={s['user-trust']} direction={PopupDirection.RIGHT} />}
      </div>
    );
  };
}
