import * as React from 'react';
import { Link } from 'react-router-dom';
import * as ReactDOM from 'react-dom';

import { IListItemProps, IRubricsLink } from '../../types/list_item_props';
import { formatDate, formatViews } from '../../../app/helpers/format_helper';
import { getLinkForCard } from '../../../app/helpers/link_card_helper';
import { getCategory } from '../../../app/helpers/category_helper';
import { unescapeHtml } from '../../utils';
import { IRubric } from '../../reducers/modules/rubrics_menu/rubrics_menu';

import s from './list_item.css';

export interface IListItemStoreProps {
  menuItems: IRubric[];
}

export interface IListItemOwnProps {
  item: IListItemProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveScroll?(item: any): void;
  selectedItem?: IListItemProps;
  scrollComments(): void;
}

interface IState {
  isVisited?: boolean;
  isHover?: boolean;
}

export type TListItemPrpos = IListItemStoreProps & IListItemOwnProps;

export class ListItem extends React.Component<TListItemPrpos, IState> {
  public linkCard: HTMLAnchorElement | null;

  public constructor(props: TListItemPrpos) {
    super(props);
    this.state = {
      isVisited: false,
      isHover: false,
    };
  }

  public componentDidMount() {
    this.setState({
      isVisited: this.isVisited(),
    });

    if (this.props.selectedItem && this.props.selectedItem.id === this.props.item.id) {
      this.scrollToEl();
    }
  }

  public render() {
    const { attributes, id } = this.props.item;
    const type = attributes.type || this.props.item.type.toLowerCase();
    const listItemType = new Map([
      ['articles', 'http://schema.org/Article'],
      ['news', 'http://schema.org/NewsArticle'],
    ]);

    return (
      <div className={s['list-item']} itemScope itemType={listItemType.get(type)}>
        {attributes.imageThumbnail && (
          <Link
            className={`
              ${s['list-item__link_img']}
              ${this.state.isVisited ? s['list-item__link_img-visited'] : null}
              ${s['list-item__img-mobile']}
            `}
            to={getLinkForCard(id, type, attributes.slug)}
            onMouseOver={this.onHover}
            onMouseOut={this.offHover}
            onClick={this.selectedElement}
            rel={attributes.noIndex ? 'nofollow' : undefined}
          >
            <img src={attributes.imageThumbnailMobile} alt={attributes.title} itemProp="image" />
          </Link>
        )}
        <div
          itemProp="articleBody"
          className={`${s['list-item__content']}
            ${!attributes.imageThumbnail && s['list-item__content-full']}`}
        >
          <div>
            {this.getRubricsLink()}
            {attributes.advertising && <span className={s['list-item__ads-caption']}>на правах рекламы</span>}
          </div>
          <Link
            className={`${s['list-item__title']}
                ${s['list-item__link']}
                ${this.state.isVisited ? s['list-item__link-visited'] : null}
                ${this.state.isHover ? s['list-item__title-hover'] : null}
              `}
            to={`${getLinkForCard(id, type, attributes.slug)}`}
            itemProp="headline"
            onMouseOver={this.onHover}
            onMouseOut={this.offHover}
            onClick={this.selectedElement}
            rel={attributes.noIndex ? 'nofollow' : undefined}
          >
            {unescapeHtml(attributes.title)}
          </Link>
          <p className={s['list-item__description-wrap']}>
            <Link
              className={`${s['list-item__link']}`}
              to={`${getLinkForCard(id, type, attributes.slug)}`}
              ref={linkCard => (this.linkCard = linkCard)}
              onMouseOver={this.onHover}
              onMouseOut={this.offHover}
              onClick={this.selectedElement}
              rel={attributes.noIndex ? 'nofollow' : undefined}
            >
              <span
                className={`
                    ${s['list-item__description']}
                    ${this.state.isVisited ? s['list-item__link-visited'] : null}
                  `}
                itemProp="description"
              >
                {unescapeHtml(attributes.subtitle)}
              </span>
            </Link>
          </p>
          <div className={s['post-info']} itemProp="hasPart" itemScope itemType="http://schema.org/PublicationIssue">
            {attributes.author && (
              <div className={s['post-info_author']}>
                <span className={s['post-info_author__name']}>{attributes.author}</span>
              </div>
            )}
            <span
              className={`
                  ${s['list-item__date']}
                `}
              itemProp="datePublished"
            >
              {formatDate(attributes.datePublish)}
            </span>
            <span className={s['list-item__views']}>
              <span className={s['views-icon']} /> <span>{formatViews(attributes.numViews, 3)}</span>
            </span>
            {!attributes.disableComments && attributes.commentsCount > 0 && (
              <Link
                to={`${getLinkForCard(id, type, attributes.slug)}`}
                className={s['list-item__comments']}
                onClick={this.props.scrollComments}
                rel={attributes.noIndex ? 'nofollow' : undefined}
              >
                <span className={s['comments-icon']} /> <span>{formatViews(attributes.commentsCount, 3)}</span>
              </Link>
            )}
          </div>
        </div>
        {attributes.imageThumbnail && (
          <Link
            className={`
              ${s['list-item__link_img']}
              ${this.state.isVisited ? s['list-item__link_img-visited'] : null}
              ${s['list-item__img']}
            `}
            to={getLinkForCard(id, type, attributes.slug)}
            onMouseOver={this.onHover}
            onMouseOut={this.offHover}
            onClick={this.selectedElement}
            rel={attributes.noIndex ? 'nofollow' : undefined}
          >
            <img src={attributes.imageThumbnail} alt={attributes.title} itemProp="image" />
          </Link>
        )}
      </div>
    );
  }

  private getRubricsLink = () => {
    const { menuItems, item } = this.props;
    const { attributes } = item;
    const type = attributes.type || item.type.toLowerCase();

    if (attributes.rubrics && attributes.rubrics.length > 0) {
      const links = attributes.rubrics.map((rubric, index) => {
        const rubricInfo = typeof rubric === 'number' ? menuItems.find(menuItem => menuItem.id === rubric) : rubric;

        if (rubricInfo) {
          return (
            <Link
              key={`${index}_${rubricInfo.id}`}
              className={`${s['list-item__category']}
                ${this.state.isVisited ? s['list-item__link_category-visited'] : null}
              `}
              to={{
                pathname:
                  'link' in rubricInfo
                    ? (rubricInfo as IRubricsLink).link.replace(/([^/])$/, '$1/')
                    : (rubricInfo as IRubric).pathname,
              }}
              onClick={this.scrollTop}
            >
              {rubricInfo.name}
            </Link>
          );
        }

        return null;
      });

      return <span className={s['list-item__category-container']}>{links}</span>;
    }

    if (type !== 'articles') {
      return (
        <Link
          className={`${s['list-item__category']}
              ${this.state.isVisited ? s['list-item__link_category-visited'] : null}
            `}
          to={{ pathname: `/${getCategory(type).linkType}/` }}
          itemProp="url"
          onClick={this.scrollTop}
        >
          {getCategory(type).category}
        </Link>
      );
    }

    return <span className={s['list-item__category-empty']} />;
  };

  private isVisited() {
    const { attributes, id } = this.props.item;
    const visitedLinks = localStorage['visitedLinks'] ? JSON.parse(localStorage['visitedLinks']) : [];
    const type = attributes.type || this.props.item.type;

    return visitedLinks.includes(getLinkForCard(id, type, attributes.slug));
  }

  private scrollTop = () => {
    window.scrollTo(0, 0);
  };

  private onHover = () => {
    this.setState({ isHover: true });
  };

  private offHover = () => {
    this.setState({ isHover: false });
  };

  private selectedElement = () => {
    if (this.props.saveScroll) {
      this.props.saveScroll(this.props.item);
    }
  };

  private scrollToEl = () => {
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this);
    if (node && node instanceof Element) {
      node.scrollIntoView();
    }
  };
}
