import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SidebarNews, SidebarNewsSkeleton } from '../../components/SidebarNews';
import { getDateForNDaysAgo } from '../../utils';
import { selectNews } from '../../selectors/postCard';
import { getPostCardNews, resetPostCardNews } from '../../actions/postCardNews';
import { POST_CARD_NEWS_REQUEST_END_DATE, POST_CARD_NEWS_REQUEST_LIMIT } from '../../constants';
import { TThunkDispatch } from '../../types/redux';
import { ERequestStatus } from '../../types/requestStatus';
import { EOrdering } from '../../repositories/journal/v2/get-news-list';

export const SidebarNewsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const { items: news, status: newsStatus } = useSelector(selectNews);
  const [prevPathname, setPrevPathname] = useState(pathname);

  /**
   * Запрашивает список, если еще ни разу не запрашивал
   * или сменился чувствительный параметр
   */
  useEffect(() => {
    if (newsStatus !== ERequestStatus.Initial && pathname === prevPathname) {
      return;
    }

    void dispatch(
      getPostCardNews({
        ordering: EOrdering.Date_publish,
        limit: POST_CARD_NEWS_REQUEST_LIMIT,
        endDate: getDateForNDaysAgo(POST_CARD_NEWS_REQUEST_END_DATE).toISOString(),
      }),
    );
    setPrevPathname(pathname);
  }, [dispatch, newsStatus, pathname, prevPathname]);

  /**
   * При выходе сбрасывает список
   */
  useEffect(() => {
    return () => {
      dispatch(resetPostCardNews());
    };
  }, [dispatch]);

  /**
   * Скелетон только для первичной загрузки
   */
  if (newsStatus === ERequestStatus.Loading && !news.length) {
    return <SidebarNewsSkeleton />;
  }

  /**
   * Для всех статусов с пустым списком (кроме Loading)
   */
  if (!news?.length) {
    return null;
  }

  return <SidebarNews items={news} isLoading={newsStatus === ERequestStatus.Loading && Boolean(news.length)} />;
};
