import * as React from 'react';

export const VKWidget = () => {
  return (
    <iframe
      name="fXD-cian-vk-widget"
      src="https://vk.com/widget_community.php?gid=115699231&width=292&height=187"
      width="100%"
      height="220"
      frameBorder="0"
    />
  );
};
