import { IPostContentRecommendedOffersGroups } from '../../types/postContentRecommendedOffers';
import {
  EGetPostContentOffersActionType,
  TGetPostContentOffersActions,
} from '../../actions/postContentRecommendedOffers';
import { ERequestStatus } from '../../types/requestStatus';
import { createPostContentRecommendedOffers } from '../../utils';

export const defaultState: IPostContentRecommendedOffersGroups = {
  items: [],
};

export const postContentRecommendedOffersReducer = (state = defaultState, action: TGetPostContentOffersActions) => {
  switch (action.type) {
    case EGetPostContentOffersActionType.Loading: {
      const index = state.items.findIndex(item => item.id === action.payload);
      const item = index > -1 ? state.items[index] : createPostContentRecommendedOffers(action.payload);

      return {
        ...state,
        items: [
          ...state.items.slice(0, index > -1 ? index : state.items.length),
          { ...item, status: ERequestStatus.Loading },
          ...state.items.slice((index > -1 ? index : state.items.length) + 1),
        ],
      };
    }
    case EGetPostContentOffersActionType.Succeed: {
      const { id } = action.payload;
      const index = state.items.findIndex(item => item.id === id);
      const item = index > -1 ? state.items[index] : createPostContentRecommendedOffers(id);

      return {
        ...state,
        items: [
          ...state.items.slice(0, index > -1 ? index : state.items.length),
          { ...item, ...action.payload, status: ERequestStatus.Succeed },
          ...state.items.slice((index > -1 ? index : state.items.length) + 1),
        ],
      };
    }

    case EGetPostContentOffersActionType.Failed: {
      const index = state.items.findIndex(item => item.id === action.payload);
      const item = index > -1 ? state.items[index] : createPostContentRecommendedOffers(action.payload);

      return {
        ...state,
        items: [
          ...state.items.slice(0, index > -1 ? index : state.items.length),
          { ...item, status: ERequestStatus.Failed },
          ...state.items.slice((index > -1 ? index : state.items.length) + 1),
        ],
      };
    }

    case EGetPostContentOffersActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};
