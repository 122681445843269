import React from 'react';
import { Link } from 'react-router-dom';
import { UIHeading3 } from '@cian/ui-kit';

import { IGetSeoUrlsResponseData } from '../../services/getSeoUrls';
import * as s from './SeoUrls.css';

interface ISeoUrls {
  items: IGetSeoUrlsResponseData[];
  title?: string;
}

/**
 * Список seo-урлов
 */
export const SeoUrls = ({ items, title }: ISeoUrls) => (
  <div className={s['wrapper']}>
    {title && <UIHeading3>{title}</UIHeading3>}

    <ul className={s['list-wrapper']}>
      {items.map(({ title, url }, index) => (
        <li key={index} className={s['item-wrapper']}>
          <Link to={url} className={s['link']}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
