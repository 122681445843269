import React from 'react';

import { Skeleton } from '../../../Skeleton';
import * as s from './SubscribeSkeleton.css';

/**
 * Скелетон подписки на комментарии
 */
export const SubscribeSkeleton = () => (
  <div className={s['wrapper']}>
    <Skeleton width={227} height={20} />
    <Skeleton width={44} height={28} borderRadius={14} />
  </div>
);
