import React from 'react';

import { Skeleton } from '../../../Skeleton';
import * as s from './SidebarNewsItem.css';

/**
 * Скелетон элемента ленты новостей в сайдбаре поста
 */
export const SidebarNewsItemSkeleton = () => {
  return (
    <div className={s['inner-wrapper']}>
      <div className={s['text']}>
        <Skeleton width={130} height={16} margin={'0 0 6px 0'} />
        <Skeleton width={150} height={16} margin={'0 0 6px 0'} />
        <Skeleton width={100} height={16} />

        <div className={s['footnote']}>
          <Skeleton width={'60%'} height={14} margin={'10px 0 0 0'} />
        </div>
      </div>

      <Skeleton width={72} height={72} />
    </div>
  );
};
