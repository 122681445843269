/* eslint-disable */
import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';

import {
  TGetSeoUrlsModel,
  IGetSeoUrlsRequest,
  TGetSeoUrlsResponse,
} from './types';

const defaultConfig: TGetSeoUrlsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'backend-content',
  pathApi: '/v1/get-seo-urls',
  hostType: 'api',
};

function createGetSeoUrlsModel(parameters: IGetSeoUrlsRequest): TGetSeoUrlsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetSeoUrlsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetSeoUrlsRequest;
  config?: IHttpApiFetchConfig;
}

async function fetchGetSeoUrls<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
}: IGetSeoUrlsOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetSeoUrlsResponse
  > {
  return await httpApi.fetch(createGetSeoUrlsModel(parameters), { ...config, requestConfig: { withCredentials: true } });
}

export { defaultConfig, createGetSeoUrlsModel, fetchGetSeoUrls };
