import { fetchGetSeoUrls, TGetSeoUrlsResponse, IGetSeoUrlsRequest } from '../../../services/getSeoUrls';
import { throwError } from '../../../utils';
import { getSeoUrlsLoading } from './getSeoUrlsLoading';
import { getSeoUrlsSucceed } from './getSeoUrlsSucceed';
import { getSeoUrlsFailed } from './getSeoUrlsFailed';
import { TThunkAction } from '../../../types/redux';

export interface IGetSeoUrlsParams {
  postId: IGetSeoUrlsRequest['id'];
}

export const getSeoUrls = ({ postId }: IGetSeoUrlsParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getSeoUrlsLoading());

    try {
      const { response, statusCode }: TGetSeoUrlsResponse = await fetchGetSeoUrls({
        httpApi,
        parameters: {
          id: postId,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось загрузить список seo-урлов', domain: 'actions.getSeoUrls', statusCode });

        return;
      }

      dispatch(getSeoUrlsSucceed({ items: response.data }));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getSeoUrls',
      });

      dispatch(getSeoUrlsFailed());
    }
  };
};
