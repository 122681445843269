import React, { useEffect } from 'react';
import { UIHeading2 } from '@cian/ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectSimilarArticles, selectSimilarArticlesStatus } from '../../selectors/similarArticles';
import { SimilarArticle } from '../../components/SimilarArticle';
import { PostTypeName, PostTypeUrl } from '../../constants';
import { TThunkDispatch } from '../../types/redux';
import { ERequestStatus } from '../../types/requestStatus';
import { getSimilarArticles } from '../../actions/similarArticles';
import * as s from './SimilarArticlesContainer.css';

/**
 * Выводит список подобных статей
 */
export const SimilarArticlesContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const articles = useSelector(selectSimilarArticles);
  const articlesStatus = useSelector(selectSimilarArticlesStatus);
  const { pathname } = useLocation();

  /** Загружает список подобных статей */
  useEffect(() => {
    const currentPostId = parseInt(String(pathname).slice(pathname.lastIndexOf('-') + 1), 10);

    /** Если статьи предварительно загружены -> ничего не делаем */
    if (articlesStatus !== ERequestStatus.Initial || !currentPostId) {
      return;
    }

    dispatch(getSimilarArticles({ journalIds: [currentPostId] })).finally();
  }, [articlesStatus, dispatch, pathname]);

  /**
   * При клике по плашке статьи роутит на соответствующую страницу статьи
   * При клике по плашке категории роутит на соответствующую категорию
   */
  const handleClick = (url: string) => {
    window.location.href = url;
  };

  if (!articles?.length) {
    return null;
  }

  return (
    <div className={`${s['wrapper']}`}>
      <div className={`${s['title-wrapper']}`}>
        <UIHeading2>Читайте по теме</UIHeading2>
      </div>

      {articles.map(({ title, imageThumbnail, url, subtitle, datePublish, numViews, type }, index) => (
        <div key={`similar-article-item-${index}`} className={`${s['similar-article-wrapper']}`}>
          <SimilarArticle
            title={title || ''}
            description={subtitle}
            imageUrl={imageThumbnail || ''}
            articleUrl={url}
            datePublish={datePublish || undefined}
            numViews={numViews || undefined}
            categoryName={PostTypeName[type]}
            categoryUrl={PostTypeUrl[type]}
            onArticleClick={() => handleClick(url)}
            onCategoryClick={() => handleClick(PostTypeUrl[type])}
          />
        </div>
      ))}
    </div>
  );
};
