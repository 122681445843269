import React, { CSSProperties } from 'react';
import { useDeviceType, LinkButton, UIHeading2 } from '@cian/ui-kit';

import { Scrolling, ScrollingWithControls } from '../Scrolling';
import { PostContentRecommendedOffersGroup } from '../PostContentRecommendedOffersGroup';
import { TPostContentRecommendedOffersState } from '../../types/postContentRecommendedOffers';
import { IRecommendationOfferSchema } from '../../repositories/journal/entities/recommendations/RecommendationOfferSchema';
import * as s from './PostContentRecommendedOffersSlider.css';

interface IPostContentRecommendedOffersSlider {
  group: TPostContentRecommendedOffersState;
  offerWidth: CSSProperties['width'];
  offerHeight: CSSProperties['height'];
  scrollLength: number;
  onOfferClick(offer: IRecommendationOfferSchema, index: number): void;
}

/**
 * Слайдер рекомендованных оферов в теле поста
 */
export const PostContentRecommendedOffersSlider = ({
  group,
  offerWidth,
  offerHeight,
  scrollLength,
  onOfferClick,
}: IPostContentRecommendedOffersSlider) => {
  const deviceType = useDeviceType();
  const isDesktop = () => deviceType === 'desktop';
  const isPhone = () => deviceType === 'phone';

  const slides = isDesktop() ? (
    <ScrollingWithControls scrollLength={scrollLength} controlsSize={'M'} controlsType={'absolute'}>
      <PostContentRecommendedOffersGroup
        group={group}
        width={offerWidth}
        height={offerHeight}
        onOfferClick={onOfferClick}
      />
    </ScrollingWithControls>
  ) : (
    <Scrolling>
      <PostContentRecommendedOffersGroup
        group={group}
        width={offerWidth}
        height={offerHeight}
        onOfferClick={onOfferClick}
      />
    </Scrolling>
  );

  return (
    <div className={s['wrapper']}>
      <div className={`${s['title-wrapper']} ${s['button-wrapper']}`}>
        {group.title && <UIHeading2>{group.title}</UIHeading2>}
        {!isPhone() && group.url && (
          <LinkButton theme={'fill_secondary'} href={group.url} role="button" target="_blank">
            Смотреть все
          </LinkButton>
        )}
      </div>

      {slides}

      {isPhone() && group.url && (
        <div className={s['button-wrapper']}>
          <LinkButton theme={'fill_secondary'} href={group.url} role="button" target="_blank">
            Смотреть все
          </LinkButton>
        </div>
      )}
    </div>
  );
};
