/* eslint-disable react/no-danger */
import React from 'react';

import { unescapeHtml, makeHTMLFromString } from '../../../utils';
import { IFullListAttributesV2Schema } from '../../../repositories/journal/entities/journal/FullListAttributesV2Schema';
import { EType as EPostType } from '../../../repositories/journal/entities/journal/JournalListAttributesSchema';

/**
 * Подготавливает описание
 */
export const prepareSubtitle = (
  subtitle: IFullListAttributesV2Schema['subtitle'],
  postType: IFullListAttributesV2Schema['type'],
  hasImage: boolean = false,
) => {
  if (!subtitle) {
    return;
  }

  // Для постов с картинкой (кроме блога/вопроса) не выводим описание
  if (hasImage && ![EPostType.Blogs, EPostType.Questions].includes(postType)) {
    return;
  }

  if ([EPostType.Blogs, EPostType.Questions].includes(postType)) {
    return <span dangerouslySetInnerHTML={makeHTMLFromString(subtitle)} />;
  }

  return unescapeHtml(subtitle);
};
