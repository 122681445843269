import * as React from 'react';

import { SidebarDiscussionsContainer } from '../SidebarDiscussions';
import { SidebarNewsContainer } from '../SidebarNews';
import { SidebarSubscribeFormContainer } from '../SidebarSubscribeForm';
import { VKWidget } from '../../components/VKWidget';
import { AdfoxBannerArticleAside } from '../../components/AdfoxBanner';

export const SidebarContainer = () => (
  <>
    <SidebarDiscussionsContainer />
    <SidebarNewsContainer />
    <AdfoxBannerArticleAside />
    <VKWidget />
    <SidebarSubscribeFormContainer />
  </>
);
