import React from 'react';

import { Skeleton } from '../../../Skeleton';
import * as s from './Likes.css';

/**
 * Скелетон кнопок лайк/дизлайк
 */
export const LikesSkeleton = () => {
  return (
    <div className={s['wrapper']}>
      <Skeleton width={30} height={16} />
      <Skeleton width={30} height={16} />
    </div>
  );
};
