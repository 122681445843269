import React from 'react';
import { useSelector } from 'react-redux';
import { LinkButton, useDeviceType } from '@cian/ui-kit';

import { IconActionDownload16 } from '../../components/Icons';
import { PostDocumentLinkLayout } from '../../components/PostDocumentLinkLayout';
import { trackDocumentLinkClick } from './utils';
import { selectPost } from '../../selectors/post';

export const PostDocumentLinkContainer = () => {
  const post = useSelector(selectPost);
  const deviceType = useDeviceType();

  /** Трекает скачивание файла */
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    trackDocumentLinkClick(
      decodeURIComponent((e.currentTarget.getAttribute('href') || '').replace(/^.*\/(.*)$/, '$1')),
    );
  };

  if (!('id' in post)) {
    return null;
  }

  const {
    attributes: { documentUrl, documentUrlText },
  } = post;

  if (!documentUrl) {
    return null;
  }

  return (
    <PostDocumentLinkLayout>
      <LinkButton
        size={'L'}
        beforeIcon={<IconActionDownload16 />}
        fullWidth={deviceType === 'phone'}
        href={documentUrl}
        target={'_blank'}
        onClick={handleClick}
      >
        {documentUrlText || 'Скачать документ'}
      </LinkButton>
    </PostDocumentLinkLayout>
  );
};
