import { EType } from '../repositories/journal/entities/journal/JournalAttributesSchema';

export const PostTypeUrl: { [key in EType]: string } = {
  [EType.Articles]: '/magazine/',
  [EType.Blogs]: '/blogs/',
  [EType.News]: '/novosti/',
  [EType.Questions]: '/forum-rieltorov/',
  [EType.Quizzes]: '/magazine/',
};

export const PostTypeName: { [key in EType]: string } = {
  [EType.Articles]: 'Статьи',
  [EType.Blogs]: 'Блоги',
  [EType.News]: 'Новости',
  [EType.Questions]: 'Вопросы',
  [EType.Quizzes]: 'Опросы',
};

export const PostTypeAsPostCardPrefix: { [key in EType]: string } = {
  [EType.Articles]: 'stati',
  [EType.Blogs]: 'blogs',
  [EType.News]: 'novosti',
  [EType.Questions]: 'forum-rieltorov',
  [EType.Quizzes]: 'quizzes',
};

export const PostTypeAsContentTypeModel: { [key in EType]: string } = {
  [EType.Articles]: 'articles',
  [EType.Blogs]: 'blog',
  [EType.News]: 'news',
  [EType.Questions]: 'question',
  [EType.Quizzes]: 'quizzes',
};

export const PostCardPrefixAsPostType: { [key: string]: EType } = {
  [PostTypeAsPostCardPrefix[EType.Articles]]: EType.Articles,
  [PostTypeAsPostCardPrefix[EType.Blogs]]: EType.Blogs,
  [PostTypeAsPostCardPrefix[EType.News]]: EType.News,
  [PostTypeAsPostCardPrefix[EType.Questions]]: EType.Questions,
  [PostTypeAsPostCardPrefix[EType.Quizzes]]: EType.Quizzes,
};
