import React from 'react';

import { SortButtonSkeleton } from './components/SortButton';
import { HeadingSkeleton } from './components/Heading';
import { SubscribeSkeleton } from './components/Subscribe';
import * as s from './PostCommentsHeader.css';

interface IPostCommentsHeaderSkeleton {
  hasSortButton?: boolean;
}

/**
 * Скелетон шапки листинга комментариев
 */
export const PostCommentsHeaderSkeleton = ({ hasSortButton = false }: IPostCommentsHeaderSkeleton) => {
  return (
    <div className={s['wrapper']}>
      <HeadingSkeleton />
      {hasSortButton && <SortButtonSkeleton />}
      <SubscribeSkeleton />
    </div>
  );
};
