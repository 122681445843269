import React, { CSSProperties } from 'react';

import { RecommendedOffer } from '../RecommendedOffer';
import { TPostContentRecommendedOffersState } from '../../types/postContentRecommendedOffers';
import { numberWithDelimiter } from '../../utils';
import { IRecommendationOfferSchema } from '../../repositories/journal/entities/recommendations/RecommendationOfferSchema';
import { ETransportType } from '../../repositories/search-engine/entities/recommended_offers/UndergroundForRecommendationSchema';

interface IPostContentRecommendedOffersGroup {
  group: TPostContentRecommendedOffersState;
  width: CSSProperties['width'];
  height: CSSProperties['height'];
  onOfferClick(offer: IRecommendationOfferSchema, index: number): void;
}

/**
 * Список рекомендованных оферов в теле поста
 */
export const PostContentRecommendedOffersGroup = ({
  group,
  width,
  height,
  onOfferClick,
}: IPostContentRecommendedOffersGroup) => (
  <>
    {group.items.map((offer, index) => {
      const underground = offer.undergroundsName
        ? {
            time: offer.undergroundsMinute ? String(offer.undergroundsMinute) : undefined,
            transportType: offer.undergroundsTransportType as unknown as ETransportType,
            color: offer.undergroundLineColor || 'FF0000',
            name: offer.undergroundsName,
          }
        : undefined;

      return (
        <RecommendedOffer
          key={`recommended-offer-${index}`}
          width={width}
          height={height}
          imageUrl={offer.imageUrl}
          title={offer.description}
          formattedPrice={`${numberWithDelimiter(offer.price)} ${offer.unit}`}
          offerUrl={offer.url}
          address={offer.address}
          underground={underground}
          onClick={() => onOfferClick(offer, index)}
        />
      );
    })}
  </>
);
