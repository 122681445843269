import { EffectCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TDependencyName, useSensitiveParams } from '../useSensitiveParams';
import { IEventTrackingData, useEventTracking } from '../useEventTracking';
import { useSearchParams } from '../useSearchParams';
import { selectPost } from '../../selectors/post';
import { ERequestStatus } from '../../types/requestStatus';

interface IUseGetPostOptions {
  dependencyNames?: TDependencyName[];
  eventTrackerData?: IEventTrackingData;
}

/**
 * Выполняет callback для запроса поста
 */
export const useGetPost = (
  callback: EffectCallback,
  { dependencyNames = ['pathname'], eventTrackerData }: IUseGetPostOptions = {},
) => {
  const { status } = useSelector(selectPost);
  const key = useSensitiveParams(dependencyNames);
  const [prevKey, setPrevKey] = useState(key);
  const { trackPageView } = useEventTracking(eventTrackerData);
  const { page, commentId } = useSearchParams<{ page: number; commentId: number }>(['page', 'commentId']);

  /**
   * Запускает callback, если хотя бы соблюдено одно из условий:
   * - пост находится в статусе инициализации
   * - слепок значений из чувствительных ключей изменился
   * Трекает callback
   */
  useEffect(() => {
    if (status !== ERequestStatus.Initial && key === prevKey) {
      return;
    }

    callback();
    setPrevKey(key);
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, status, prevKey]);

  /**
   * Скролим наверх пока идет загрузка
   */
  useEffect(() => {
    if (status !== ERequestStatus.Loading || page > 1 || commentId) {
      return;
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
};
