/* eslint-disable max-lines */
import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as queryString from 'qs';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ca } from '@cian/analytics';
import { ThunkDispatch } from 'redux-thunk';
import { TDeviceType } from '@cian/ui-kit';

import { createMakeRequest } from '../../../app/helpers/network_helper';
import { IBreadItem } from '../../types/bread_crumb';
import { IApplicationState, IThunkContext, TReduxActions, TThunkDispatch } from '../../types/redux';
import { IRegionsResponse } from '../../types/regions';
import { IUser } from '../../types/user';
import { IComment, ICommentState, IPostItem, ISimilarJournalItem } from '../../types/posts';
import { IConfig } from '../../types/config';
import { IPostCard } from '../../types/post_card';
import { IGlobalErrorState } from '../../types/global_error';
import { editCard, getPostCard, hideCard, publishCard } from '../../reducers/modules/post_card/post_card';
import { PostCard as PostCardComponent } from '../../components/post_card/post_card';
import {
  ApplicationContext,
  formatDateToYYYYMMDD,
  escapeHtml,
  unescapeHtml,
  makeHTMLFromString,
  extractAndReplaceAdfox,
  extractAndReplaceBeforeAfterGalleries,
  extractAndReplaceBlockquotes,
  extractAndReplaceCollapsers,
  extractAndReplaceJournalBanners,
  extractAndReplacePhotoGalleries,
  extractAndReplaceRecommendedOffers,
  extractAndReplaceYoutube,
  useContentAdfoxBanners,
  useContentBeforeAfterGalleries,
  useContentBlockquotes,
  useContentCollapsers,
  useContentJournalBanners,
  useContentPhotoGalleries,
  useContentRecommendedOffers,
  useContentScripts,
  useContentYoutubeVideos,
  IExtractAndReplaceJournalBannersItem,
  IHandleSliderClickParams,
} from '../../utils';
import { Aside } from '../aside/aside';
import { setBreadCrumb } from '../../reducers/modules/bread_crumbs/bread_crumbs';
import { setSimilarArticles, showReadTopic } from '../../reducers/modules/read_topic/read_topic';
import { deleteComment, editComment, hideComment, submitComment } from '../../reducers/modules/comments';
import { ReadTopicMobile } from '../read_topic/read_topic';
import { getDiscussed } from '../../reducers/modules/discussed/discussed';
import {
  scrollCommentDisabled,
  scrollCommentForm,
  scrollComments,
} from '../../reducers/modules/scroll_comment/scroll_comment';
import { IScrollComment } from '../../types/scroll_comment';
import { refreshScroll } from '../../reducers/modules/scroll/scroll';
import { LoadError } from '../../components/load_error/load_error';
import { combineDataLayerObjectNew } from '../../../app/helpers/combine_data_layer_object';
import { getBreadCrumbsNames } from '../../../app/helpers/breadcrumbs_helper';
import { IAnalytics } from '../../types/analytics';
import * as Analytics from '../../../app/helpers/analytics_helper';
import { getSeoUrls, resetSeoUrls } from '../../reducers/modules/seo_urls/seo_urls';
import { SeoUrlsMobile } from '../seo_urls/seo_urls';
import { IRubric } from '../../reducers/modules/rubrics_menu/rubrics_menu';
import { getMenuRubricsData, selectRubric } from '../../../app/utils';
import { getSimilarArticlesReset } from '../../actions/similarArticles';
import { selectPostContentRecommendedOfferGroups } from '../../selectors/postContentRecommendedOffers';
import { getPostContentOffers, getPostContentOffersReset } from '../../actions/postContentRecommendedOffers';
import { POST_CONTENT_RECOMMENDED_OFFERS_REQUEST_LIMIT } from '../../constants';
import { EOfferCategories } from '../../repositories/journal/v1/get-recommendations';
import s from './post_card.css';

interface IProps {
  postCard: IPostCard;
  comments: ICommentState;
  user: IUser;
  globalError: IGlobalErrorState;
  scrollComment: IScrollComment;
  config: IConfig;
  analytics: IAnalytics;
  deviceType: TDeviceType;
  menuItems: IRubric[];
}

interface IDispatchProps {
  showReadTopic(status: boolean): void;
  setSimilarArticles(items: ISimilarJournalItem[]): void;
  setBreadCrumb(path: IBreadItem[]): void;
  getDiscussed(regionID: string): void;
  resetSeoUrls(): void;
  submitComment(
    itemId: string,
    contentType: string,
    comment: string,
    userId: number,
    fullName: string,
    email: string,
    parentID: number | null,
    subs: boolean,
    user: IUser,
  ): void;
  scrollCommentDisabled(): void;
  hideCard(type: string, id: string): void;
  publishCard(type: string, id: string, content: string, regions: IRegionsResponse[], title: string): void;
  scrollComments(): void;
  editCard(): void;
  editComment(id: number, comment: string, email: string, user: IUser): void;
  deleteComment(id: number, email: string): void;
  hideComment(id: number, email: string): void;
  refreshScroll(): void;
  scrollCommentForm(): void;
  getPostCard(pathname: string, params?: { name: string; value: string | number }[]): void;
  getSeoUrls(type: 'stati' | 'blogs' | 'forum-rieltorov', id: number): void;
  resetSimilarArticles(): void;
}

interface ISubscribeToCommentsBody {
  user_id: number | null;
  email: string;
  content_type_model: string;
  object_pk: string;
  subscribe_news?: boolean;
}

export interface ISubscribeToCommentsResponse {
  errors?: string;
  data: {
    attributes?: {
      contentTypeModel?: string;
      email?: string;
      objectPk?: number;
      password?: string;
      userId?: number;
    };
    id: string;
    type: string;
  };
}

type Props = IProps & IDispatchProps & RouteComponentProps<{ id: string }>;

const TYPES: { [key: string]: string } = {
  Article: 'article',
  Articles: 'article',
  News: 'news',
  Blog: 'blog',
  Blogs: 'blog',
  Questions: 'question',
  Question: 'question',
};
const URLS: { [key: string]: string } = {
  Article: '/stati',
  Articles: '/stati',
  News: '/novosti',
  Blog: '/blogs',
  Blogs: '/blogs',
  Questions: '/forum-rieltorov',
  Question: '/forum-rieltorov',
};
const CATEGORY_PREFIXES: { [key: string]: string } = {
  Article: '',
  Articles: '',
  News: '',
  Blog: 'b-',
  Blogs: 'b-',
  Questions: 'q-',
  Question: 'q-',
};

export const setCrumbsPostCard = (item: IPostItem, menuItems: IRubric[]) => {
  const { rubrics } = item.attributes;

  const crumbs = [{ path: '/magazine' }];
  const type = item.type;
  const category = item.attributes.category;

  let articlePath = {};
  /**
   * Пытаемся искать рубрику для поста с конца, так как новые
   * рубрики к посту добавляются в конец, выводим всегда самую свежую
   */
  const rubricForPost = menuItems.reverse().find(menuItem => rubrics && rubrics.includes(menuItem.id));

  if (type === 'Articles' && rubricForPost) {
    articlePath = {
      ...articlePath,
      path: rubricForPost.pathname,
      name: rubricForPost.name,
      url: rubricForPost.pathname,
    };

    crumbs.push(articlePath as { path: string; name: string; url: string });
  } else {
    crumbs.push({
      path: URLS[type] || '',
    });
  }

  if (category && CATEGORY_PREFIXES[type] !== undefined) {
    crumbs.push({ path: `${CATEGORY_PREFIXES[type]}${category}` });
  }

  return crumbs;
};

const PostCardContainer: React.FC<Props> = props => {
  const [subscribed, setSubscribed] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [subscribeError, setSubscribeError] = useState('');
  const context = useContext(ApplicationContext);
  const { content } = props.postCard.item.attributes;
  const postContentRecommendedOffers = useSelector(selectPostContentRecommendedOfferGroups);
  const dispatch = useDispatch<TThunkDispatch>();

  /**
   * Подготавливает контент поста к рендеру:
   * вырезает адфокс-параметры, скрипты, санитизирует HTML
   */
  const {
    makeHTMLFromStringScripts,
    extractedRecommendedOffersItems,
    extractedCollapsersItems,
    extractedAdfoxItems,
    extractedYoutubeItems,
    extractedJournalBannersItems,
    extractedBeforeAfterGalleriesItems,
    extractedBlockquotesItems,
    extractedPhotoGalleriesItems,
    html,
  } = useMemo(() => {
    const makeHTMLFromStringData = makeHTMLFromString(content);
    const extractedRecommendedOffers = extractAndReplaceRecommendedOffers(makeHTMLFromStringData.__html, true);
    const extractedCollapsers = extractAndReplaceCollapsers(extractedRecommendedOffers.html);
    const extractedAdfox = extractAndReplaceAdfox(extractedCollapsers.html);
    const extractedYoutube = extractAndReplaceYoutube(extractedAdfox.html);
    const extractedJournalBanners = extractAndReplaceJournalBanners(extractedYoutube.html);
    const extractedBeforeAfterGalleries = extractAndReplaceBeforeAfterGalleries(extractedJournalBanners.html);
    const extractedBlockquotes = extractAndReplaceBlockquotes(extractedBeforeAfterGalleries.html);
    const extractedPhotoGalleries = extractAndReplacePhotoGalleries(extractedBlockquotes.html);

    return {
      makeHTMLFromStringScripts: makeHTMLFromStringData.scripts
        .filter(script => ['https://ceditor.setka.io/'].some(host => script.src.startsWith(host)))
        .map(script => script.src),
      extractedRecommendedOffersItems: extractedRecommendedOffers.items,
      extractedCollapsersItems: extractedCollapsers.items,
      extractedAdfoxItems: extractedAdfox.items,
      extractedYoutubeItems: extractedYoutube.items,
      extractedJournalBannersItems: extractedJournalBanners.items,
      extractedBeforeAfterGalleriesItems: extractedBeforeAfterGalleries.items,
      extractedBlockquotesItems: extractedBlockquotes.items,
      extractedPhotoGalleriesItems: extractedPhotoGalleries.items,
      html: extractedPhotoGalleries.html,
    };
  }, [content]);

  type TCardCategory = 'stati' | 'novosti' | 'blogs' | 'forum-rieltorov';
  const categories: TCardCategory[] = ['stati', 'novosti', 'blogs', 'forum-rieltorov'];
  const {
    config,
    user,
    match: { params, path },
  } = props;
  const regionID = config.regionID && config.regionID !== '0' ? config.regionID : '';
  const foundIndex = categories.findIndex(category => path.includes(category));
  const category = categories[foundIndex] || '';

  /** Трекает событие клика по баннеру */
  const handleJournalBannerClick = (url: string, position: IExtractAndReplaceJournalBannersItem['position']) => {
    const crumbs = setCrumbsPostCard(props.postCard.item, props.menuItems);

    ca('event', {
      name: 'oldevent',
      category: 'Magazine',
      action: `click_${position}_banner`,
      label: url,
      ...combineDataLayerObjectNew(props.analytics, {
        page: {
          breadCrumbs: getBreadCrumbsNames(crumbs),
          siteType: props.deviceType === 'phone' ? 'mobile' : props.deviceType,
        },
      }),
    });
  };

  /** Трекает событие клика по рекомендованному оферу */
  const handleRecommendedOfferClick = ({ offerId, url, index, position, fromDeveloper }: IHandleSliderClickParams) => {
    const crumbs = setCrumbsPostCard(props.postCard.item, props.menuItems);

    ca('event', {
      name: 'oldevent',
      category: 'Magazine',
      action: `to_card_${position}_recommendations`,
      label: `${url}from_developer=${Number(fromDeveloper)}`,
      ...combineDataLayerObjectNew(props.analytics, {
        page: {
          breadCrumbs: getBreadCrumbsNames(crumbs),
          siteType: props.deviceType === 'phone' ? 'mobile' : props.deviceType,
          extra: {
            type: index + 1,
          },
        },
        product: { id: offerId },
      }),
    });
  };

  /** Трекает событие раскрытия коллапсера */
  const handleCollapserToggleOpened = () => {
    const crumbs = setCrumbsPostCard(props.postCard.item, props.menuItems);

    ca('event', {
      name: 'oldevent',
      category: 'Magazine',
      action: 'click_collapser',
      label: '',
      ...combineDataLayerObjectNew(props.analytics, {
        page: {
          breadCrumbs: getBreadCrumbsNames(crumbs),
          siteType: props.deviceType === 'phone' ? 'mobile' : props.deviceType,
        },
      }),
    });
  };

  let postContent = '';
  useContentScripts(makeHTMLFromStringScripts);
  useContentCollapsers(extractedCollapsersItems, handleCollapserToggleOpened);
  useContentAdfoxBanners(extractedAdfoxItems);
  useContentYoutubeVideos(extractedYoutubeItems);
  useContentJournalBanners(extractedJournalBannersItems, handleJournalBannerClick);

  postContent = useContentRecommendedOffers(html, handleRecommendedOfferClick);
  postContent = useContentBeforeAfterGalleries({ html: postContent, galleries: extractedBeforeAfterGalleriesItems });
  postContent = useContentBlockquotes({
    html: postContent,
    blockquotes: extractedBlockquotesItems,
    deviceType: props.deviceType,
  });
  postContent = useContentPhotoGalleries({ html: postContent, galleries: extractedPhotoGalleriesItems });

  /** Запрашивает рекомендованные оферы/сохраняет в стор */
  useEffect(() => {
    if (extractedRecommendedOffersItems.length && !postContentRecommendedOffers.length) {
      extractedRecommendedOffersItems.map(
        async ({ geoId, maxPrice, minPrice, offerCategories, rooms, id, title, url, position }) => {
          await dispatch(
            getPostContentOffers({
              id,
              title,
              url,
              position,
              geoId: geoId ? parseInt(geoId, 10) : undefined,
              maxPrice: maxPrice ? parseInt(maxPrice, 10) : undefined,
              minPrice: minPrice ? parseInt(minPrice, 10) : undefined,
              offerCategories: (offerCategories as EOfferCategories[]) || undefined,
              rooms: rooms ? rooms.map(room => parseInt(room, 10)) : undefined,
              limit: POST_CONTENT_RECOMMENDED_OFFERS_REQUEST_LIMIT,
            }),
          );
        },
      );
    }
  }, [dispatch, extractedRecommendedOffersItems, postContentRecommendedOffers]);

  /** При выходе сбрасывает рекомендованные оферы */
  useEffect(() => {
    return () => {
      dispatch(getPostContentOffersReset());
    };
  }, [dispatch]);

  useEffect(() => {
    const {
      custom: { subdomain },
    } = context;

    props.getDiscussed(regionID);

    if (category && category !== 'novosti' && subdomain === 'www') {
      const idRaw = String(params.id).slice(String(params.id).lastIndexOf('-') + 1);
      if (!idRaw || !/^\d+$/.test(idRaw) || idRaw.startsWith('0')) {
        props.resetSeoUrls();
      } else {
        props.getSeoUrls(category, parseInt(idRaw, 10));
      }
    } else {
      props.resetSeoUrls();
    }

    return () => {
      const SHOW_TOPIC = false;

      props.showReadTopic(SHOW_TOPIC);
      props.setSimilarArticles([]);

      props.resetSeoUrls();
    };
  }, []);

  useEffect(() => {
    const id = params.id;

    if (category === 'stati') {
      props.getPostCard(`/v1/get-article`, [
        {
          name: 'id',
          value: id,
        },
        {
          name: 'user_id',
          value: user.userId,
        },
      ]);
    } else if (category === 'novosti') {
      props.getPostCard(`/v1/get-news`, [
        {
          name: 'id',
          value: id,
        },
        {
          name: 'user_id',
          value: user.userId,
        },
      ]);
    } else if (category === 'blogs') {
      props.getPostCard(`/v1/get-blog`, [
        {
          name: 'id',
          value: id,
        },
        {
          name: 'user_id',
          value: user.userId,
        },
      ]);
    } else if (category === 'forum-rieltorov') {
      props.getPostCard(`/v1/get-question`, [
        {
          name: 'id',
          value: id,
        },
        {
          name: 'user_id',
          value: user.userId,
        },
      ]);
    }

    return () => {
      // Сбрасывает блок подобных статей
      props.resetSimilarArticles();
    };
  }, [params.id]);

  useEffect(() => {
    if (Number(props.postCard.item.id) === 0) {
      return;
    }
    if (!params.id.includes(props.postCard.item.id)) {
      return;
    }

    updateLinkedInfo();
    if (props.postCard.item.attributes.status === 'hidden') {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const browserHistory = useHistory();
      if (props.postCard.item.type === 'Question') {
        browserHistory.replace('/forum-rieltorov');
      } else if (props.postCard.item.type === 'Blog') {
        browserHistory.replace('/blogs');
      }
    }
  }, [props.postCard.item.id]);

  const onCommentEdit = (id: number, comment: string, email: string) => {
    props.editComment(id, comment, email, props.user);
  };

  const onCommentDelete = (id: number, email: string) => {
    props.deleteComment(id, email);
  };

  const onCommentHide = (id: number, email: string) => {
    props.hideComment(id, email);
  };

  const refreshScroll = () => {
    props.refreshScroll();
  };

  function isMobileOrTablet() {
    return props.deviceType === 'phone' || props.deviceType === 'tablet';
  }

  const handleOpenCommentForm = () => {
    if (props.user.userId === -1) {
      window.waitForLoginPopup();

      return;
    }

    props.scrollCommentForm();
  };

  function updateLinkedInfo() {
    const SHOW_TOPIC = true;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setBreadCrumb();
    props.showReadTopic(SHOW_TOPIC);
    props.setSimilarArticles(props.postCard.item.attributes.similarJournals || []);
  }

  function getComments(comments: IComment[], parentID: number | null) {
    const commentsGraph = comments.filter((item: IComment) => item.parentId === parentID);

    if (!parentID) {
      commentsGraph.map((item: IComment) => {
        item.comments = getComments(comments, Number(item.id));
      });
    }

    return commentsGraph;
  }

  const setBreadCrumb = () => {
    const { postCard } = props;
    const { rubrics, datePublish, author, category, advertising, tags } = postCard.item.attributes;
    const crumbs = setCrumbsPostCard(postCard.item, props.menuItems);

    const getJournalArticleType = (category: string = '', advertising: boolean = false, tags: string[] = []) => {
      // Явная метка при редактировании статьи - это рекламная статья
      if (advertising) {
        return 'advertising';
      }

      // Наличие тега "на правах рекламы" - это рекламная статья
      if (tags?.length && tags.map(tag => tag.toLowerCase()).includes('на правах рекламы')) {
        return 'advertising';
      }

      // Наличие тега "партнерские проекты" - это рекламная статья
      if (tags?.length && tags.map(tag => tag.toLowerCase()).includes('партнерские проекты')) {
        return 'advertising';
      }

      // Одноименные категории отправляем как есть
      if (['interview', 'analytics', 'seo'].includes(category)) {
        return category;
      }

      return 'other';
    };

    if (typeof window !== 'undefined') {
      const rubricsForAnalytics =
        props.menuItems
          .filter(menuItem => rubrics && rubrics.includes(menuItem.id))
          .map(menuItem => menuItem.name)
          .join(',') || undefined;

      ca(
        'pageviewSite',
        combineDataLayerObjectNew(props.analytics, {
          page: {
            breadCrumbs: getBreadCrumbsNames(crumbs),
            siteType: props.deviceType === 'phone' ? 'mobile' : props.deviceType,
            extra: {
              journalPageType: rubricsForAnalytics && 'Article',
              journalRubrics: rubricsForAnalytics,
              publicationDate: datePublish && formatDateToYYYYMMDD(new Date(datePublish)),
              author,
              journalArticleType: getJournalArticleType(category, advertising, tags),
            },
          },
        }),
      );
    }

    props.setBreadCrumb(crumbs);
  };

  function subscribeToComments(email: string, isSubscribe: boolean, isNews: boolean = false) {
    const makeRequest = createMakeRequest(
      context.httpApi,
      window.__content_api_host__,
      window.__content_api_schema__,
      window.__content_api_path__,
    );
    const body = {
      user_id: props.user.userId > 0 ? props.user.userId : null,
      email,
      content_type_model: props.postCard.item.type.toLowerCase(),
      object_pk: props.postCard.item.id,
    } as ISubscribeToCommentsBody;
    let path;

    if (isSubscribe) {
      path = '/v2/subscribe-comment';
      body['subscribe_news'] = isNews;
    } else {
      path = '/v1/unsubscribe-comment';
    }
    const {
      custom: { subdomain },
    } = context;

    return makeRequest({
      method: 'POST',
      uri: {
        subdomain,
        path,
      },
      headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
      body: queryString.stringify(body),
      currentUser: props.user,
    })
      .then((res: ISubscribeToCommentsResponse) => {
        if (res.errors) {
          throw res.errors;
        }
        setSubscribed(isSubscribe);
        setUnsubscribed(!isSubscribe);
        setSubscribeError('');
        reloadCard();

        return res;
      })
      .catch((err: Error) => {
        setSubscribed(false);
        setUnsubscribed(false);
        setSubscribeError('error');
      });
  }

  function reloadCard() {
    props.getPostCard(
      `/v1/get-${TYPES[props.postCard.item.type]}?id=${props.postCard.item.id}&user_id=${props.user.userId}`,
    );
  }

  return (
    <div>
      {props.globalError.error ? (
        <LoadError />
      ) : (
        <PostCardComponent
          menuItems={props.menuItems}
          regionId={regionID}
          postCard={props.postCard}
          preparedContent={escapeHtml(postContent)}
          commentsCount={props.postCard.item.attributes.comments ? props.postCard.item.attributes.comments.length : 0}
          user={props.user}
          onCommentSubmit={(
            comment: string,
            userId: number,
            fullName: string,
            email: string,
            parentID: number | null,
            subs: boolean,
          ) =>
            props.submitComment(
              props.postCard.item.id,
              props.postCard.item.type,
              comment,
              userId,
              fullName,
              email,
              parentID,
              subs,
              props.user,
            )
          }
          onCommentEdit={onCommentEdit}
          onCommentDelete={onCommentDelete}
          onCommentHide={onCommentHide}
          onDigestSubmit={(email: string, sub: boolean, isNews?: boolean) => subscribeToComments(email, sub, isNews)}
          onDigestReset={() => {
            setSubscribed(false);
            setUnsubscribed(false);
            setSubscribeError('');
          }}
          isSubscribed={subscribed}
          isUnsubscribed={unsubscribed}
          error={subscribeError}
          commentsState={props.comments}
          comments={getComments(props.postCard.item.attributes.comments || [], null)}
          onScrollCommentForm={handleOpenCommentForm}
          scrollCommentState={props.scrollComment}
          scrollCommentDisabled={() => props.scrollCommentDisabled()}
          onHide={() => props.hideCard(TYPES[props.postCard.item.type], props.postCard.item.id)}
          onPublish={() => {
            Analytics.addPost('add_post');
            props.publishCard(
              TYPES[props.postCard.item.type],
              props.postCard.item.id,
              unescapeHtml(props.postCard.item.attributes.content),
              props.postCard.item.attributes.regions,
              props.postCard.item.attributes.title,
            );
          }}
          onScrollComments={() => props.scrollComments()}
          onEdit={() => props.editCard()}
          refreshScroll={refreshScroll}
          onJournalBannerClick={handleJournalBannerClick}
        />
      )}
      {isMobileOrTablet() && <ReadTopicMobile />}
      {isMobileOrTablet() && <SeoUrlsMobile />}
      <div className={s['aside-wrap']}>
        <div className={s['journal__bottom-line_mobile']} />
        <Aside />
      </div>
    </div>
  );
};

function mapStateToProps(state: IApplicationState): IProps {
  const { postCard, user, comments, scrollComment, config, globalError, analytics, deviceType, menuRubrics } = state;

  return {
    postCard,
    user,
    comments,
    scrollComment,
    config,
    globalError,
    analytics,
    deviceType,
    menuItems: getMenuRubricsData(menuRubrics.items)(selectRubric),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IApplicationState, IThunkContext, TReduxActions>): IDispatchProps {
  return {
    showReadTopic: (status: boolean) => {
      dispatch(showReadTopic(status));
    },
    setSimilarArticles: (items: ISimilarJournalItem[]) => {
      dispatch(setSimilarArticles(items));
    },
    setBreadCrumb: (path: IBreadItem[]) => {
      dispatch(setBreadCrumb(path));
    },
    getDiscussed: (regionID: string) => {
      dispatch(getDiscussed(regionID));
    },
    resetSeoUrls: () => {
      dispatch(resetSeoUrls());
    },
    submitComment: (
      itemId: string,
      contentType: string,
      comment: string,
      userId: number,
      fullName: string,
      email: string,
      parentID: number | null,
      subs: boolean,
      user: IUser,
    ) => {
      dispatch(submitComment(itemId, contentType, comment, userId, fullName, email, parentID, subs, user));
    },
    scrollCommentDisabled: () => {
      dispatch(scrollCommentDisabled());
    },
    hideCard: (type: string, id: string) => {
      dispatch(hideCard(type, id));
    },
    publishCard: (type: string, id: string, content: string, regions: IRegionsResponse[], title: string) => {
      dispatch(publishCard(type, id, content, regions, title));
    },
    scrollComments: () => {
      dispatch(scrollComments());
    },
    editCard: () => {
      dispatch(editCard());
    },
    editComment: (id: number, comment: string, email: string, user: IUser) => {
      dispatch(editComment(id, comment, email, user));
    },
    deleteComment: (id: number, email: string) => {
      dispatch(deleteComment(id, email));
    },
    hideComment: (id: number, email: string) => {
      dispatch(hideComment(id, email));
    },
    refreshScroll: () => {
      dispatch(refreshScroll());
    },
    scrollCommentForm: () => {
      dispatch(scrollCommentForm());
    },
    getPostCard: (pathname: string, params?: { name: string; value: string | number }[]) => {
      dispatch(getPostCard(pathname, params));
    },
    getSeoUrls: (type: 'stati' | 'blogs' | 'forum-rieltorov', id: number) => {
      dispatch(getSeoUrls(type, id));
    },
    resetSimilarArticles: () => {
      dispatch(getSimilarArticlesReset());
    },
  };
}

export const PostCard = connect(mapStateToProps, mapDispatchToProps)(PostCardContainer);
