/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetLikesModel,
  IGetLikesRequest,
  IMappers,
  IGetLikesResponse,
  IGetLikesResponseError,
  TGetLikesResponse,
} from './types';

const defaultConfig: TGetLikesModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/get-likes/',
  hostType: 'api',
} as TGetLikesModel;

function createGetLikesModel(parameters: IGetLikesRequest): TGetLikesModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetLikesOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetLikesRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetLikes<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetLikesOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetLikesResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetLikesModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetLikesResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetLikesResponseError);
    }
  }

  return { response, statusCode } as TGetLikesResponse;
}

export { defaultConfig, createGetLikesModel, fetchGetLikes };
