import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PostParents, PostParentsSkeleton } from '../../components/PostParents';
import { selectPost } from '../../selectors/post';
import { selectPageMetaItems } from '../../selectors/pagesMeta';
import { PostTypeName, PostTypeUrl } from '../../constants';
import { EType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';
import { ERequestStatus } from '../../types/requestStatus';

export interface IPostParentItem {
  name: string;
  pathname: string;
}

/**
 * Контейнер родителей поста (категории или рубрики)
 */
export const PostParentsContainer = () => {
  const post = useSelector(selectPost);
  const pagesMeta = useSelector(selectPageMetaItems);
  const parents = useMemo(() => {
    if (!('id' in post)) {
      return [];
    }

    const parents: IPostParentItem[] = [];
    const {
      attributes: { rubrics, category, type },
    } = post;

    if (type !== EType.Articles) {
      parents.push({
        name: PostTypeName[type],
        pathname: PostTypeUrl[type],
      });
    }

    if (type === EType.Articles && rubrics?.length) {
      rubrics.map(rubricId => {
        const pageMeta = pagesMeta.find(pageMeta => pageMeta.rubricId === rubricId);

        if (pageMeta) {
          parents.push({
            name: pageMeta.breadCrumbName,
            pathname: pageMeta.pathname,
          });
        }
      });
    }

    if (category && [EType.Blogs, EType.News, EType.Questions].includes(type)) {
      const pageMeta = pagesMeta.find(pageMeta => pageMeta.category === category);

      if (pageMeta) {
        parents.push({
          name: pageMeta.breadCrumbName,
          pathname: pageMeta.pathname,
        });
      }
    }

    return parents;
  }, [pagesMeta, post]);

  if (post.status === ERequestStatus.Loading) {
    return <PostParentsSkeleton />;
  }

  if (!parents?.length) {
    return null;
  }

  return <PostParents items={parents} />;
};
