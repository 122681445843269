import { IPostCard } from '../../../shared/types/post_card';
import { IList } from '../../../shared/types/list_item_props';

export function getTimeDiff({
  item: {
    attributes: { datePublish },
  },
}: IPostCard | IList): number {
  return Math.abs(new Date().getTime() - new Date(datePublish).getTime());
}

export function getTimeDiffNew(date: Date): number {
  return Math.abs(new Date().getTime() - new Date(date).getTime());
}
