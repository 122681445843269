import { TThunkAction } from '../../../types/redux';
import { fetchAddLike, TAddLikeResponse, IAddLikeRequest } from '../../../services/likes/addLike';
import { getPostLikeCountsSucceed } from '../getPostLikeCounts';
import { throwError } from '../../../utils';
import { PostTypeAsContentTypeModel } from '../../../constants';
import { ELikeType } from '../../../types/likes';
import { EType } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';

export interface IAddLikeForBlogOrQuestion {
  postId: IAddLikeRequest['object_pk'];
  postType: EType.Blogs | EType.Questions;
  likeType: ELikeType;
}

export const addLikeForBlogOrQuestion = ({
  postId,
  postType,
  likeType,
}: IAddLikeForBlogOrQuestion): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    try {
      const { response, statusCode }: TAddLikeResponse = await fetchAddLike({
        httpApi,
        parameters: {
          content_type_model: PostTypeAsContentTypeModel[postType],
          object_pk: postId,
          vote: likeType,
        },
      });

      if (statusCode !== 201) {
        throwError({ message: 'Не удалось добавить лайк', statusCode, domain: 'actions.addLikeForBlogOrQuestion' });

        return false;
      }

      const {
        attributes: { dislikesCount, likesCount, vote },
      } = response.data;

      dispatch(
        getPostLikeCountsSucceed({
          dislikesCount,
          likesCount,
          userLikeValue: vote,
        }),
      );

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.addLikeForBlogOrQuestion',
      });

      return false;
    }
  };
};
