import sanitizeHtml from 'sanitize-html';

import { unescapeHtml } from '../unescapeHtml';

/**
 * Производит санитизацию комментария к посту
 */
export const sanitizePostComment = (string: string) =>
  sanitizeHtml(unescapeHtml(string), {
    allowedTags: ['br'],
  });
