import React, { useCallback } from 'react';
import { Like16 as IconSocialLikeOn16, Dislike16 as IconSocialDislikeOn16 } from '@cian/ui-kit';

import { IconWithCount } from '../../../../components/IconWithText';
import { PostCommentsCount } from '../../../../components/PostCommentsCount';
import { ELikeType } from '../../../../types/likes';
import * as s from './Counts.css';

interface ICounts {
  isCommentsShown?: boolean;
  likesCount?: number;
  dislikesCount?: number;
  commentsCount?: number;
  postCommentsUrl?: string;
  userLikeValue?: number;
  isLikesDisabled?: boolean;
  onLikeClick?(likeType: ELikeType): void;
}

/**
 * Каунты лайков и комментов
 */
export const Counts = ({
  likesCount,
  dislikesCount,
  commentsCount,
  isCommentsShown,
  postCommentsUrl,
  userLikeValue,
  isLikesDisabled,
  onLikeClick,
}: ICounts) => {
  const clickableClass = onLikeClick && !userLikeValue && !isLikesDisabled ? s['_clickable'] : '';
  const likedClass = userLikeValue === ELikeType.Like ? s['_liked'] : '';
  const dislikedClass = userLikeValue === ELikeType.Dislike ? s['_disliked'] : '';

  const handleLikeClick = useCallback(
    (likeType: ELikeType) => {
      if (userLikeValue || !onLikeClick) {
        return;
      }

      onLikeClick(likeType);
    },
    [onLikeClick, userLikeValue],
  );

  return (
    <div className={s['wrapper']}>
      <div className={s['likes-wrapper']}>
        <div
          className={`${s['like-wrapper']} ${clickableClass} ${likedClass}`}
          onClick={() => handleLikeClick(ELikeType.Like)}
        >
          <IconWithCount count={likesCount || 0} icon={<IconSocialLikeOn16 />} gap={'var(--unit_2)'} />
        </div>

        <div
          className={`${s['like-wrapper']} ${clickableClass} ${dislikedClass}`}
          onClick={() => handleLikeClick(ELikeType.Dislike)}
        >
          <IconWithCount count={dislikesCount || 0} icon={<IconSocialDislikeOn16 />} gap={'var(--unit_2)'} />
        </div>
      </div>

      {isCommentsShown && <PostCommentsCount link={postCommentsUrl} count={commentsCount || 0} />}
    </div>
  );
};
