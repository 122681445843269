import React from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { IconWithCount, IconWithText } from '../../../IconWithText';
import { IconMessage16 } from '../../../Icons';

export interface ICount {
  count: number;
}

/**
 * Количество комментариев с иконкой
 */
export const Count = ({ count }: ICount) => {
  const deviceType = useDeviceType();

  if (count) {
    return <IconWithCount count={count} icon={<IconMessage16 />} gap={'var(--unit_2)'} />;
  }

  return (
    <IconWithText
      text={deviceType === 'phone' ? '' : 'Обсудить'}
      icon={<IconMessage16 />}
      gap={deviceType === 'phone' ? 0 : 'var(--unit_2)'}
    />
  );
};
