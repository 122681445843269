import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useIsMounted } from '../../../../utils';
import { POST_COMMENT_ID_PREFIX } from '../../../../constants';
import { selectComments } from '../../../../selectors/postCard';
import { ERequestStatus } from '../../../../types/requestStatus';

/**
 * Плавно скроллит до комментария и обновляет параметры в qs
 */
export const useScrollIntoCurrentPostComment = (delayMs: number = 1000) => {
  const { search } = useLocation();
  const history = useHistory();
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const isMounted = useIsMounted();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const {
    status,
    meta: { offset, limit },
  } = useSelector(selectComments);
  const [isUpdateDisallowed, setIsUpdateDisallowed] = useState(false);

  /**
   * Пересчитывает page, опираясь на полученные значения offset и limit.
   */
  const recalculateQsPage = useCallback(() => {
    const calculatedPage = Math.ceil(offset / limit) + 1;

    if (calculatedPage === 1) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', String(calculatedPage));
    }
  }, [limit, offset, searchParams]);

  /**
   * Обновляет параметры qs.
   * Выставляет запрет на обновление листинга isListingUpdateForbidden = true,
   * чтобы измененные параметры в qs не вызывали экшен обновления
   */
  useEffect(() => {
    const commentId = searchParams.get('comment-id');

    if (status !== ERequestStatus.Succeed || !commentId) {
      return;
    }

    clearTimeout(scrollTimeoutRef.current);

    /**
     * Ожидаем пока браузер все отрисует,
     * чтобы наверняка был нужный коммент
     */
    scrollTimeoutRef.current = setTimeout(() => {
      if (!isMounted()) {
        return;
      }
      /** Скроллит до нужного коммента */
      document.getElementById(`${POST_COMMENT_ID_PREFIX}-${commentId}`)?.scrollIntoView({ behavior: 'smooth' });
      /** Удаляем id, чтобы он не попадал в следующие запросы к апи */
      searchParams.delete('comment-id');
      /** Корректирует страницу, так как запрос к апи с commentId ее меняет */
      recalculateQsPage();
      /** Обновляет qs */
      history.replace(`?${searchParams.toString()}`);
      /** Включает временный запрет на обращение к апи (из-за удаления comment-id и изменения page) */
      setIsUpdateDisallowed(true);
    }, delayMs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isMounted, status]);

  /**
   * Сбрасывает запрет на обновление листинга
   */
  useEffect(() => {
    if (!isUpdateDisallowed) {
      return;
    }

    setIsUpdateDisallowed(false);
  }, [isUpdateDisallowed]);

  return isUpdateDisallowed;
};
