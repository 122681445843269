import React from 'react';

import { ListItemSkeleton } from './components/ListItem';
import * as s from './PostCommentList.css';

interface IPostCommentListSkeletonItem {
  items?: IPostCommentListSkeletonItem[];
}

interface IPostCommentListSkeleton {
  items: IPostCommentListSkeletonItem[];
}

/**
 * Скелетон списка комментариев
 */
export const PostCommentListSkeleton = ({ items }: IPostCommentListSkeleton) => {
  return (
    <div className={s['wrapper']}>
      {items.map((item, index) => {
        return (
          <ListItemSkeleton key={index}>
            {item?.items?.length && <PostCommentListSkeleton items={item.items} />}
          </ListItemSkeleton>
        );
      })}
    </div>
  );
};
