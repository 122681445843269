import React from 'react';
import { ArticleParagraph1, ArticleParagraph2, Image } from '@cian/ui-kit';
import { Link } from 'react-router-dom';

import { buildPostUrl, formatDate } from '../../../../utils';
import { ListingItemType } from '../../../../constants';
import { IArticlesNewsListAttributesV2Schema } from '../../../../repositories/journal/entities/journal/ArticlesNewsListAttributesV2Schema';
import { IArticlesNewsListDataV2Schema } from '../../../../repositories/journal/entities/journal/ArticlesNewsListDataV2Schema';
import * as s from './SidebarNewsItem.css';

type ISidebarNewsItem = Pick<IArticlesNewsListDataV2Schema, 'id'> &
  Pick<IArticlesNewsListAttributesV2Schema, 'title' | 'type' | 'datePublish' | 'slug' | 'imageThumbnail'>;

/**
 * Элемент ленты новостей в сайдбаре
 */
export const SidebarNewsItem = ({ title, type, slug, datePublish, id, imageThumbnail }: ISidebarNewsItem) => {
  return (
    <article className={s['wrapper']} itemScope itemType={ListingItemType[type]}>
      <Link to={`${buildPostUrl({ type, slug, id })}`} className={s['inner-wrapper']} itemProp="articleBody">
        <div className={s['text']}>
          <ArticleParagraph1 itemProp="headline">{title}</ArticleParagraph1>

          <div className={s['footnote']} itemProp="hasPart" itemScope itemType="http://schema.org/PublicationIssue">
            <ArticleParagraph2 itemProp="datePublished" color={'gray60_100'}>
              {formatDate(new Date(datePublish))}
            </ArticleParagraph2>
          </div>
        </div>

        {imageThumbnail && (
          <Image
            width={72}
            height={72}
            alt={title}
            borderRadius={'4px'}
            objectFit="cover"
            preloader
            src={imageThumbnail}
            theme="light"
            title={title}
            itemProp="image"
          />
        )}
      </Link>
    </article>
  );
};
