import React from 'react';
import { Link } from 'react-router-dom';

import { buildPostUrl } from '../../utils';
import { EType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { TAuthorArticlesItem } from '../../types/authorArticles';
import * as s from './AuthorArticles.css';

interface IAuthorArticles {
  items: TAuthorArticlesItem[];
}

/**
 * Список статей автора
 */
export const AuthorArticles = ({ items }: IAuthorArticles) => (
  <div className={s['wrapper']}>
    <h2 className={s['title']}>Статьи автора</h2>

    <ul className={s['list-wrapper']}>
      {items.map(({ title, id, slug }, index) => (
        <li key={`author-article-${index}`} className={s['item-wrapper']}>
          <Link to={buildPostUrl({ type: EType.Articles, slug, id })} className={s['link']}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
