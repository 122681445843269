import React, { CSSProperties } from 'react';

import * as s from './IconWithText.css';

export interface IIconWithText {
  text: string;
  icon: React.ReactNode;
  gap?: CSSProperties['gap'];
}

/**
 * Иконка и текст
 */
export const IconWithText = ({ text, icon, gap }: IIconWithText) => (
  <div className={s['wrapper']} style={{ gap }}>
    <div className={s['icon']}>{icon}</div>
    <div className={s['text']}>{text}</div>
  </div>
);
