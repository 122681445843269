import React from 'react';
import { LinkButton } from '@cian/ui-kit';

import { IPaginationItem } from '../../types/pagination';
import s from './PaginationNew.css';

interface IPaginationNewParams {
  /** Коллекция отображаемых страниц */
  pages: IPaginationItem[];
  /** Номер активной страницы */
  activeNumber: IPaginationItem['number'];
  /** Событие клика по странице */
  onClick(page: number): void;
  /** Отключена ли активность */
  isDisabled?: boolean;
}

/**
 * Рисует пагинацию
 */
export const PaginationNew = ({ pages, activeNumber, isDisabled, onClick }: IPaginationNewParams) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, number: IPaginationItem['number']) => {
    e.preventDefault();

    if (number === activeNumber || isDisabled) {
      return;
    }

    onClick(number);
  };

  if (!pages.length) {
    return null;
  }

  return (
    <div className={s['wrapper']}>
      {pages.map(({ label, number, link, Icon }, index) => {
        return (
          <div
            key={`page-${index}`}
            className={`${s['button-wrapper']} ${
              isDisabled ? s['_disabled'] : activeNumber === number ? s[Icon ? '_disabled' : '_active'] : ''
            }`}
          >
            <LinkButton
              beforeIcon={Icon && <Icon />}
              theme={'stroke_secondary'}
              href={link}
              size={'M'}
              onClick={e => handleClick(e, number)}
            >
              {label}
            </LinkButton>
          </div>
        );
      })}
    </div>
  );
};
