import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ERequestStatus } from '../../types/requestStatus';
import { correctPathname } from '../correctPathname';
import { useCurrentListing } from '../useCurrentListing';
import { getSomeCondition } from './utils';

export type TQSParams = 'page' | 'with-tag' | 'comment-id';

/**
 * В случае положительного ответа ручки с пустыми данными
 * при наличии qs-фильтров, убираем qs-фильтры,
 * чтобы попробовать получить данные снова
 */
export const useRemoveWrongSearchParams = (qsParams: TQSParams[] = ['page']) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { items, status } = useCurrentListing();

  useEffect(() => {
    if (status === ERequestStatus.Succeed && !items.length && getSomeCondition(qsParams, searchParams)) {
      qsParams.forEach(qsParam => {
        searchParams.delete(qsParam);
      });

      history.replace(`${correctPathname(pathname)}?${searchParams.toString()}`);
    }
  }, [items.length, history, status, pathname, qsParams, searchParams]);
};
