import React from 'react';

import { UserCardSkeleton } from '../../../UserCard';
import { LikesSkeleton } from '../Likes';
import { Skeleton } from '../../../Skeleton';
import { IListItem } from './ListItem';
import * as s from './ListItem.css';

type TListItemSkeleton = Pick<IListItem, 'children'>;

/**
 * Скелетон элемента листинга комментариев
 */
export const ListItemSkeleton = ({ children }: TListItemSkeleton) => {
  return (
    <div>
      <div className={s['wrapper']}>
        <div className={s['headnote']}>
          <UserCardSkeleton size={'L'}>
            <Skeleton width={150} height={16} />
            <Skeleton width={100} height={14} margin={'6px 0 0 0'} />
          </UserCardSkeleton>
        </div>

        <Skeleton height={80} borderRadius={'0 12px 12px 12px'} />

        <div className={s['footnote']}>
          <LikesSkeleton />
        </div>

        {children && <div className={s['comments']}>{children}</div>}
      </div>
    </div>
  );
};
