import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  extractAndReplaceAdfox,
  extractAndReplaceBeforeAfterGalleries,
  extractAndReplaceBlockquotes,
  extractAndReplaceCollapsers,
  extractAndReplaceJournalBanners,
  extractAndReplacePhotoGalleries,
  extractAndReplaceRecommendedOffers,
  extractAndReplaceYoutube,
  makeHTMLFromString,
  useContentAdfoxBanners,
  useContentBeforeAfterGalleries,
  useContentBlockquotes,
  useContentCollapsers,
  useContentJournalBanners,
  useContentPhotoGalleries,
  useContentRecommendedOffers,
  useContentScripts,
  useContentYoutubeVideos,
} from '../../utils';
import { selectPost } from '../../selectors/post';
import { selectPostContentRecommendedOfferGroups } from '../../selectors/postContentRecommendedOffers';
import { getPostContentOffers, getPostContentOffersReset } from '../../actions/postContentRecommendedOffers';
import { TThunkDispatch } from '../../types/redux';
import { useDeviceType } from '@cian/ui-kit';
import { EOfferCategories } from '../../repositories/journal/v1/get-recommendations';
import { POST_CONTENT_RECOMMENDED_OFFERS_REQUEST_LIMIT } from '../../constants';

interface IUsePreparedContent {
  handleCollapserToggleOpened?(): void;
  handleJournalBannerClick?(): void;
  handleRecommendedOfferClick?(): void;
}

/**
 * Подготавливает контент поста
 */
export const usePreparedContent = (params?: IUsePreparedContent) => {
  const { handleCollapserToggleOpened, handleJournalBannerClick, handleRecommendedOfferClick } = params || {};
  const dispatch = useDispatch<TThunkDispatch>();
  const deviceType = useDeviceType();
  const post = useSelector(selectPost);
  const postContentRecommendedOffers = useSelector(selectPostContentRecommendedOfferGroups);
  let postContent = '';

  const {
    makeHTMLFromStringScripts,
    extractedRecommendedOffersItems,
    extractedCollapsersItems,
    extractedAdfoxItems,
    extractedYoutubeItems,
    extractedJournalBannersItems,
    extractedBeforeAfterGalleriesItems,
    extractedBlockquotesItems,
    extractedPhotoGalleriesItems,
    html,
  } = useMemo(() => {
    if (!('attributes' in post) || !post?.attributes?.content) {
      return {};
    }

    const {
      attributes: { content },
    } = post;

    const makeHTMLFromStringData = makeHTMLFromString(content);
    const extractedRecommendedOffers = extractAndReplaceRecommendedOffers(makeHTMLFromStringData.__html, true);
    const extractedCollapsers = extractAndReplaceCollapsers(extractedRecommendedOffers.html);
    const extractedAdfox = extractAndReplaceAdfox(extractedCollapsers.html);
    const extractedYoutube = extractAndReplaceYoutube(extractedAdfox.html);
    const extractedJournalBanners = extractAndReplaceJournalBanners(extractedYoutube.html);
    const extractedBeforeAfterGalleries = extractAndReplaceBeforeAfterGalleries(extractedJournalBanners.html);
    const extractedBlockquotes = extractAndReplaceBlockquotes(extractedBeforeAfterGalleries.html);
    const extractedPhotoGalleries = extractAndReplacePhotoGalleries(extractedBlockquotes.html);

    return {
      makeHTMLFromStringScripts: makeHTMLFromStringData.scripts
        .filter(script => ['https://ceditor.setka.io/'].some(host => script.src.startsWith(host)))
        .map(script => script.src),
      extractedRecommendedOffersItems: extractedRecommendedOffers.items,
      extractedCollapsersItems: extractedCollapsers.items,
      extractedAdfoxItems: extractedAdfox.items,
      extractedYoutubeItems: extractedYoutube.items,
      extractedJournalBannersItems: extractedJournalBanners.items,
      extractedBeforeAfterGalleriesItems: extractedBeforeAfterGalleries.items,
      extractedBlockquotesItems: extractedBlockquotes.items,
      extractedPhotoGalleriesItems: extractedPhotoGalleries.items,
      html: extractedPhotoGalleries.html,
    };
  }, [post]);

  useContentScripts(makeHTMLFromStringScripts || []);
  useContentCollapsers(extractedCollapsersItems || [], handleCollapserToggleOpened);
  useContentAdfoxBanners(extractedAdfoxItems || []);
  useContentYoutubeVideos(extractedYoutubeItems || []);
  useContentJournalBanners(extractedJournalBannersItems || [], handleJournalBannerClick);

  postContent = useContentRecommendedOffers(html || '', handleRecommendedOfferClick);
  postContent = useContentBeforeAfterGalleries({
    html: postContent,
    galleries: extractedBeforeAfterGalleriesItems || [],
  });
  postContent = useContentBlockquotes({
    html: postContent,
    blockquotes: extractedBlockquotesItems || [],
    deviceType,
  });
  postContent = useContentPhotoGalleries({ html: postContent, galleries: extractedPhotoGalleriesItems || [] });

  /** Запрашивает рекомендованные оферы/сохраняет в стор */
  useEffect(() => {
    if (extractedRecommendedOffersItems?.length && !postContentRecommendedOffers.length) {
      extractedRecommendedOffersItems.map(
        async ({ geoId, maxPrice, minPrice, offerCategories, rooms, id, title, url, position }) => {
          await dispatch(
            getPostContentOffers({
              id,
              title,
              url,
              position,
              geoId: geoId ? parseInt(geoId, 10) : undefined,
              maxPrice: maxPrice ? parseInt(maxPrice, 10) : undefined,
              minPrice: minPrice ? parseInt(minPrice, 10) : undefined,
              offerCategories: (offerCategories as EOfferCategories[]) || undefined,
              rooms: rooms ? rooms.map(room => parseInt(room, 10)) : undefined,
              limit: POST_CONTENT_RECOMMENDED_OFFERS_REQUEST_LIMIT,
            }),
          );
        },
      );
    }
  }, [dispatch, extractedRecommendedOffersItems, postContentRecommendedOffers]);

  /** При размонтировании сбрасывает рекомендованные оферы */
  useEffect(() => {
    return () => {
      dispatch(getPostContentOffersReset());
    };
  }, [dispatch]);

  return postContent;
};
