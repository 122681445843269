import React from 'react';
import { UIHeading2 } from '@cian/ui-kit';

import { SidebarCard } from '../SidebarCard';
import { SidebarDiscussionsItem } from './components/SidebarDiscussionsItem';
import { IconCommunicationComment24 } from '../Icons';
import { IDiscussions } from '../../types/discussions';
import * as s from './SidebarDiscussions.css';

interface ISidebarDiscussions extends IDiscussions {
  isLoading?: boolean;
}

/**
 * Лента обсуждений в сайдбаре
 */
export const SidebarDiscussions = ({ items, isLoading }: ISidebarDiscussions) => (
  <SidebarCard>
    <div className={`${s['wrapper']} ${isLoading ? s['_loading'] : ''}`}>
      <UIHeading2>
        <span className={s['heading-wrapper']}>
          <IconCommunicationComment24 color={'primary_100'} />
          Сейчас обсуждают
        </span>
      </UIHeading2>

      <div className={s['items-wrapper']}>
        {items.map((item, index) => (
          <SidebarDiscussionsItem key={index} {...item} />
        ))}
      </div>
    </div>
  </SidebarCard>
);
