import * as React from 'react';

import { Skeleton } from '../../../../components/Skeleton';
import * as s from './Tabs.css';

/**
 * Скелетон табов авторов и тегов
 */
export const TabsSkeleton = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className={s['wrapper']}>
      <div className={s['tabs-wrapper']}>
        <Skeleton width={'50px'} margin={'8px 0 0 0'} />
        <Skeleton width={'40px'} margin={'8px 0 0 0'} />
      </div>

      {children && <div className={s['tabs-after']}>{children}</div>}
    </div>
  );
};
