import { preparePageNumber } from '../../../preparePageNumber';
import { prepareQSParam } from '../../../prepareQSParam';
import { TQSParams } from '../../useRemoveWrongSearchParams';

export const getSomeCondition = (qsParams: TQSParams[], searchParams: URLSearchParams): boolean => {
  return qsParams.some(key => {
    switch (key) {
      case 'page': {
        return preparePageNumber(searchParams.get('page') || '') > 1;
      }

      default:
        return prepareQSParam(searchParams.get(key));
    }
  });
};
