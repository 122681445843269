import React from 'react';

import { makeHTMLFromString } from '../../../../utils';
import * as s from './Description.css';

export interface IDescription {
  children: string;
}

/**
 * Компонент описания поста
 */
export const Description = ({ children }: IDescription) => {
  return (
    <div
      className={s['wrapper']}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={makeHTMLFromString(children)}
      itemProp="description"
    />
  );
};
