import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconActionDownload16 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00005 8.36463L9.00006 0H7.00005L7.00005 8.36463L4.1776 5.76452L2.82251 7.23548L8.00005 12.0052L13.1776 7.23548L11.8225 5.76452L9.00005 8.36463Z"
        fill={currentColor}
      />
      <path
        d="M3 10V14H13V10H15V14C15 15.1046 14.1046 16 13 16H3C1.89543 16 1 15.1046 1 14V10H3Z"
        fill={currentColor}
      />
    </svg>
  );
};
