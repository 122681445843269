import { TThunkAction } from '../../../types/redux';
import {
  EContentTypeModel,
  fetchAddLike,
  IAddLikeRequest,
  TAddLikeResponse,
} from '../../../repositories/journal/v1/add-like';
import { ELikeType } from '../../../types/likes';
import { throwError } from '../../../utils';
import { getPostLikeCountsSucceed } from '../getPostLikeCounts';
import { EType } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';

export interface IAddLike {
  postId: IAddLikeRequest['objectPk'];
  postType: EType.Articles | EType.News;
  likeType: ELikeType;
}
export const addLike = ({ postId, postType, likeType }: IAddLike): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    try {
      const { response, statusCode }: TAddLikeResponse = await fetchAddLike({
        httpApi,
        parameters: {
          contentTypeModel: postType === EType.Articles ? EContentTypeModel.Articles : EContentTypeModel.News,
          objectPk: postId,
          vote: likeType,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: response.message, statusCode, domain: 'actions.post.addLike' });

        return false;
      }

      const { dislikesCount, likesCount, userLike } = response;

      dispatch(
        getPostLikeCountsSucceed({
          dislikesCount,
          likesCount,
          userLikeValue: userLike,
        }),
      );

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.post.addLike',
      });

      return false;
    }
  };
};
