import React from 'react';

import * as s from './PostDocumentLinkLayout.css';

/**
 * Декоратор для контейнера с кнопкой скачивания шаблона
 */
export const PostDocumentLinkLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);
