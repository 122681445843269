/* eslint-disable max-lines */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, useDeviceType } from '@cian/ui-kit';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  buildPostUrl,
  prepareListingOrdering,
  preparePageNumber,
  useGetListing,
  useTimeoutEffect,
  isAuthorBlog,
  prepareUserAvatar,
  prepareQSParam,
  unescapeHtml,
} from '../../utils';
import { getBlogList, resetBlogList } from '../../actions/blogList';
import { BLOG_LIST_REQUEST_LIMIT, CIAN_URL } from '../../constants';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { selectCurrentPageMeta, selectPageMetaItems } from '../../selectors/pagesMeta';
import { ListingHeaderContainer } from '../ListingHeader';
import {
  CardListingItem,
  ListingItem,
  SkeletonCardListingItem,
  SkeletonListingItem,
} from '../../components/ListingItem';
import { ERequestStatus } from '../../types/requestStatus';
import { EType as EPostType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { PaginationContainer } from '../Pagination';
import { selectBlogList } from '../../selectors/blogList';
import { EListingTypes } from '../../types/listing';
import { selectFavoriteListingType } from '../../selectors/settings/selectFavoriteListingType';
import { EType } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import { UserCard } from '../../components/UserCard';
import { selectUser } from '../../selectors/user';
import { BreadCrumbsContainer } from '../BreadCrumbs';
import { NothingFound } from '../../components/NotFound';
import { isOwner, prepareStatus, prepareSubtitle } from './helpers';
import { BlogsSubscribeFormContainer } from '../BlogsSubscribeForm';
import * as s from './Listings.css';

/**
 * Листинг записей блога
 */
export const BlogList = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsPage = preparePageNumber(searchParams.get('page') as string);
  const qsOrdering = prepareListingOrdering(searchParams.get('ordering') || '') || '';
  const qsAuthorId = prepareQSParam(searchParams.get('author_id'));
  const appendType = useSelector(selectListingAppendType);
  const favoriteListingType = useSelector(selectFavoriteListingType);
  const {
    type: pageType,
    pathname: currentPageTypePathname,
    category,
  } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const pagesMeta = useSelector(selectPageMetaItems);
  const deviceType = useDeviceType();
  const [isShownAsList, setIsShownAsList] = useState(false);
  const isPhone = () => deviceType === 'phone';
  const isDesktop = () => deviceType === 'desktop';
  const { items: blogs, status: blogsStatus } = useSelector(selectBlogList);
  const [isSkeletonShown, setIsSkeletonShown] = useState(
    blogsStatus === ERequestStatus.Loading && appendType === 'set',
  );
  const user = useSelector(selectUser);
  // Только на десктопе в режиме карточек форма подписки стоит после 6-ого элемента
  const subscribeFormPositionIndex = isDesktop() && !isShownAsList ? 6 : 4;

  /** Если не нашел нужный тип, выкидывает на дефолтный */
  useEffect(() => {
    if (EType.Blogs !== pageType) {
      history.replace(currentPageTypePathname);
    }
  }, [currentPageTypePathname, history, pageType]);

  /** Запрашивает новости/сохраняет в стор */
  useGetListing(
    () => {
      dispatch(
        getBlogList({
          tag: undefined,
          offset: (qsPage - 1) * BLOG_LIST_REQUEST_LIMIT,
          limit: BLOG_LIST_REQUEST_LIMIT,
          setType: appendType,
          ordering: qsOrdering || undefined,
          category: category || undefined,
          authorId:
            isAuthorBlog(pathname) && user.isAuthenticated ? user.userId : parseInt(qsAuthorId, 10) || undefined,
        }),
      ).finally();
    },
    { dependencyNames: ['page', 'ordering', 'category', 'isAuthorBlog', 'deviceType', 'authorId'] },
  );

  /** При выходе сбрасывает состояние блога */
  useEffect(() => {
    return () => {
      dispatch(resetBlogList());
    };
  }, [dispatch]);

  /** Устанавливает вид листинга: карточки или список */
  useEffect(() => {
    setIsShownAsList(favoriteListingType === EListingTypes.List && deviceType !== 'phone');
  }, [favoriteListingType, deviceType]);

  /** Создает небольшую задержку перед скрытием скелетона, когда элементов не найдено */
  useTimeoutEffect(() => {
    setIsSkeletonShown(blogsStatus === ERequestStatus.Loading && appendType === 'set');
  }, [!isSkeletonShown || blogs.length ? 0 : 500]);

  /**
   * При клике по плашке новости роутит на соответствующую страницу новости
   * При клике по шильдику категории в плашке новости роутит на соответствующую категорию
   */
  const handleClick = useCallback(
    (e, url: string) => {
      e.preventDefault();
      history.push(url);
    },
    [history],
  );

  /**
   * При клике по карточке пользователя отправляет на соответствующую страницу профиля
   */
  const handleUserCardClick = (e: React.MouseEvent, url: string) => {
    e.preventDefault();
    window.location.href = url;
  };

  return (
    <>
      <ListingHeaderContainer />

      <div className={isShownAsList ? s['list-wrapper'] : s['cards-wrapper']}>
        {isSkeletonShown &&
          [...Array(6)].map((_, index) =>
            isShownAsList ? (
              <div key={index} className={s['list-item-wrapper']}>
                <SkeletonListingItem />
              </div>
            ) : (
              <Col key={index} xs={12} m={6} l={4}>
                <SkeletonCardListingItem />
              </Col>
            ),
          )}

        {((blogsStatus === ERequestStatus.Succeed && appendType === 'set') || appendType === 'append') &&
          blogs.map(
            (
              {
                id,
                attributes: {
                  image,
                  title,
                  subtitle,
                  datePublish,
                  commentsCount,
                  category,
                  slug,
                  authorObject,
                  noIndex,
                  type,
                  likesCount,
                  status,
                  blockReason,
                },
              },
              index,
            ) => {
              const pageMeta = pagesMeta.find(
                pageMeta => pageMeta.type === EType.Blogs && pageMeta.category === category,
              );

              return (
                <Fragment key={`blog-list-item-${index}`}>
                  {/* Форма подписки */}
                  {index === subscribeFormPositionIndex && (
                    <div className={`${s['insert-wrapper']} ${s['_active']}`}>
                      <BlogsSubscribeFormContainer />
                    </div>
                  )}

                  {/* Элемент списка */}
                  {isShownAsList ? (
                    <div className={s['list-item-wrapper']}>
                      <ListingItem
                        title={unescapeHtml(title)}
                        subtitle={prepareSubtitle(subtitle, type)}
                        image={image}
                        rubric={pageMeta ? { name: pageMeta.title, url: pageMeta.pathname } : undefined}
                        datePublish={datePublish}
                        numComments={commentsCount}
                        numLikes={likesCount}
                        url={buildPostUrl({ type: EPostType.Blogs, slug, id })}
                        noIndex={Boolean(noIndex)}
                        type={type}
                        status={
                          isOwner(
                            authorObject?.userId || undefined,
                            (user?.isAuthenticated && user.userId) || undefined,
                          ) && status
                            ? prepareStatus(status, blockReason || undefined)
                            : undefined
                        }
                        onRubricButtonClick={handleClick}
                        onClick={e => handleClick(e, buildPostUrl({ type: EPostType.Blogs, slug, id }))}
                      >
                        {authorObject?.userId && (
                          <UserCard
                            imageSrc={prepareUserAvatar(authorObject?.avatar || undefined)}
                            name={
                              authorObject.fullName ||
                              authorObject?.companyName ||
                              (authorObject?.userId ? `ID: ${authorObject.userId}` : 'Аноним')
                            }
                            description={(authorObject.fullName && authorObject?.companyName) || undefined}
                            isTrusted={authorObject?.userTrust || undefined}
                            url={authorObject?.specialistLink ? `${CIAN_URL}${authorObject.specialistLink}` : undefined}
                            onClick={e =>
                              authorObject?.specialistLink &&
                              handleUserCardClick(e, `${CIAN_URL}${authorObject.specialistLink}`)
                            }
                          />
                        )}
                      </ListingItem>
                    </div>
                  ) : (
                    <Col xs={12} m={6} l={4}>
                      <CardListingItem
                        title={unescapeHtml(title)}
                        subtitle={prepareSubtitle(subtitle, type)}
                        height={isPhone() ? undefined : 400}
                        rubric={pageMeta ? { name: pageMeta?.title, url: pageMeta?.pathname } : undefined}
                        datePublish={datePublish}
                        numComments={commentsCount}
                        numLikes={likesCount}
                        url={buildPostUrl({ type: EPostType.Blogs, slug, id })}
                        noIndex={Boolean(noIndex)}
                        type={type}
                        status={
                          isOwner(
                            authorObject?.userId || undefined,
                            (user?.isAuthenticated && user.userId) || undefined,
                          ) && status
                            ? prepareStatus(status, blockReason || undefined)
                            : undefined
                        }
                        onRubricButtonClick={handleClick}
                        onClick={e => handleClick(e, buildPostUrl({ type: EPostType.Blogs, slug, id }))}
                      >
                        {authorObject && authorObject?.userId && (
                          <UserCard
                            imageSrc={prepareUserAvatar(authorObject?.avatar || undefined)}
                            name={
                              authorObject.fullName ||
                              authorObject?.companyName ||
                              (authorObject?.userId ? `ID: ${authorObject.userId}` : 'Аноним')
                            }
                            description={(authorObject.fullName && authorObject?.companyName) || undefined}
                            isTrusted={authorObject?.userTrust || undefined}
                            url={authorObject?.specialistLink ? `${CIAN_URL}${authorObject.specialistLink}` : undefined}
                            onClick={e =>
                              authorObject?.specialistLink &&
                              handleUserCardClick(e, `${CIAN_URL}${authorObject.specialistLink}`)
                            }
                          />
                        )}
                      </CardListingItem>
                    </Col>
                  )}
                </Fragment>
              );
            },
          )}

        {[ERequestStatus.Succeed, ERequestStatus.Failed].includes(blogsStatus) && !blogs.length && (
          <NothingFound pathname={currentPageTypePathname} showResetFiltersLink={qsPage > 1} />
        )}
      </div>

      {blogsStatus === ERequestStatus.Succeed && (
        <div className={s['controls-wrapper']}>
          <PaginationContainer />
        </div>
      )}

      {/* Хлебные крошки для мобилки */}
      {isPhone() && (
        <div className={s['bread-crumbs-wrapper']}>
          <BreadCrumbsContainer />
        </div>
      )}
    </>
  );
};
