import sanitizeHtml from 'sanitize-html';

import { unescapeHtml } from '../../shared/utils';

const linkTemplate = /^(http:\/\/|https:\/\/)/;

const getlink = (href: string): string => `<a href="${href}" target="_blank" rel="nofollow noopener">${href}</a>`;

export const getCleanText = (dirtyText: string): string => {
  let textWithLinks = unescapeHtml(dirtyText);

  textWithLinks = textWithLinks
    .split(' ')
    .map(item => {
      return linkTemplate.test(item) ? getlink(item) : item;
    })
    .join(' ');

  return sanitizeHtml(textWithLinks, {
    allowedTags: ['a', 'br'],
    allowedAttributes: {
      a: ['href', 'target', 'rel'],
    },
  });
};
