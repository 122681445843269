import { parseDateRanges, isDateIncludedInDateRanges } from '../../utils';

export interface IExtractAndReplaceJournalBannersItem {
  /** Id баннера */
  id: string;
  /** Полное HTML-тело баннера */
  outerHtml: string;
  /** Тип баннера */
  type: string;
  /** Периоды показа */
  dateRanges?: string;
  /** Тип баннера */
  position: 'top' | 'middle' | 'bottom';
}

export interface IExtractAndReplaceJournalBanners {
  /** HTML-контент */
  html: string;
  /** Коллекция параметров для баннера */
  items: IExtractAndReplaceJournalBannersItem[];
}

/**
 * 1. Извлекает из HTML шаблон баннера
 * 2. Обрамляет шаблон баннера div'ом
 */
export const extractAndReplaceJournalBanners = (html: string): IExtractAndReplaceJournalBanners => {
  const miniBannerPattern =
    '<div[^>]*?(?:class="(minibanner)")[^>]*?>.*?<a[^>]*?(?:class="mini__button[^"]*?")[^>]*?>[^<]*?</a>[^<]*?</div>';
  const photoBannerPattern =
    '<div[^>]*?(?:class="(photo) journal_banner"[^>]*?)>.*?<a[^>]*?(?:class="photo__button[^"]*?")[^>]*?>[^<]*?</a>[^<]*?</div>[^<]*?</div>';
  const standardBannerPattern =
    '<div[^>]*?(?:class="(standard(?:-.*?)?) journal_banner")[^>]*?>.*?<img[^>]*?(?:class="standard(?:-.*?)?__img[^"]*?")[^>]*?>[^<]*?</div>[^<]*?</div>';
  const fillBannerPattern =
    '<div[^>]*?(?:class="(fill) journal_banner")[^>]*?>.*?<a[^>]*?(?:class="fill__button[^"]*?")[^>]*?>[^<]*?</a>[^<]*?</div>';
  const pattern = new RegExp(
    `(${miniBannerPattern})|(${photoBannerPattern})|(${standardBannerPattern})|(${fillBannerPattern})`,
    'gis',
  );
  const items = [] as IExtractAndReplaceJournalBannersItem[];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  let index = 0;
  while ((match = pattern.exec(html))) {
    index = html.indexOf(match[0], index);
    items.push({
      outerHtml: match[0],
      type: match[2] || match[4] || match[6] || match[8],
      dateRanges: match[0].match(/data-date-ranges="([^"]*?)"/)?.[1],
      id: `journal-banner-${items.length + 1}`,
      position: index / html.length < 0.333 ? 'top' : index / html.length < 0.667 ? 'middle' : 'bottom',
    });

    index++;
  }

  return {
    html: items.reduce((acc, item) => {
      const dateRanges = parseDateRanges(item.dateRanges || '');

      /**
       * Если заданы периоды показа и ни один не актуален для текущей даты,
       * то вырезам баннер
       */
      if (dateRanges?.length && !isDateIncludedInDateRanges(dateRanges)) {
        return acc.replace(item.outerHtml, ``);
      }

      return acc.replace(
        item.outerHtml,
        `<div id="${item.id}">${item.outerHtml
          .replace('class="minibanner"', 'class="minibanner _replaced"')
          .replace(/class="(.*) journal_banner"/, 'class="$1 journal_banner _replaced"')}</div>`,
      );
    }, html),
    items,
  };
};
