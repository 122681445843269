import { EType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { PostTypeAsPostCardPrefix } from '../../constants';

interface IBuildPostUrl {
  type: EType;
  slug: string;
  id: number;
  anchor?: string;
}

/** Строит url материала */
export const buildPostUrl = ({ type, slug, id, anchor }: IBuildPostUrl): string => {
  return `/${PostTypeAsPostCardPrefix[type]}-${slug}-${id}/${anchor ? `#${anchor}` : ''}`;
};
