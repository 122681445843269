import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ISeoUrlsNew } from '../../../types/seo_urls';

export enum EGetSeoUrlsActionType {
  Loading = 'seoUrls/getSeoUrls/loading',
  Succeed = 'seoUrls/getSeoUrls/succeed',
  Failed = 'seoUrls/getSeoUrls/failed',
}

export type TGetSeoUrlsLoading = ITypedReduxAction<EGetSeoUrlsActionType.Loading>;
export type TGetSeoUrlsSucceed = ITypedReduxAction<EGetSeoUrlsActionType.Succeed, ISeoUrlsNew>;
export type TGetSeoUrlsFailed = ITypedReduxAction<EGetSeoUrlsActionType.Failed>;

export type TGetSeoUrls = TGetSeoUrlsLoading | TGetSeoUrlsSucceed | TGetSeoUrlsFailed;
