import React, { forwardRef } from 'react';

import { POST_COMMENTS_ID } from '../../constants';
import * as s from './PostCommentsLayout.css';

export interface IPostCommentsLayout {
  children: React.ReactNode;
}

/**
 * Декоратор для компонента PostCommentsContainer
 */
export const PostCommentsLayout = forwardRef<HTMLDivElement, IPostCommentsLayout>(({ children }, ref) => (
  <div id={POST_COMMENTS_ID} ref={ref} className={s['wrapper']}>
    {children}
  </div>
));

PostCommentsLayout.displayName = 'PostCommentsLayout';
