import React, { memo } from 'react';
import { useDeviceType } from '@cian/ui-kit';
import { AdfoxBannerArticleAsideMobile } from './AdfoxBannerArticleAsideMobile';
import { AdfoxBannerArticleAsideDesktop } from './AdfoxBannerArticleAsideDesktop';

export const AdfoxBannerArticleAside = memo(function AdfoxBannerArticleFooter() {
  const deviceType = useDeviceType();

  return <div>{deviceType === 'desktop' ? <AdfoxBannerArticleAsideDesktop /> : <AdfoxBannerArticleAsideMobile />}</div>;
});
