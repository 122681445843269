import React from 'react';

import { Skeleton } from '../../../Skeleton';

/**
 * Скелетон заголовка комментариев
 */
export const HeadingSkeleton = () => {
  return <Skeleton width={165} height={22} />;
};
