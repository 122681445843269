import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SeoUrls } from '../../components/SeoUrls';
import { PostCardPrefixAsPostType, PostTypeName } from '../../constants';
import { parsePostCardPath } from '../../utils';
import { getSeoUrls, resetSeoUrls } from '../../actions/seoUrls';
import { selectSeoUrls } from '../../selectors/seoUrls';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { ERequestStatus } from '../../types/requestStatus';
import { EType as EPageType } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import { EType as EPostType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';

/** Список похожих материалов */
export const SeoUrlsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const { id: qsPostId, prefix } = parsePostCardPath(pathname);
  const { items, status } = useSelector(selectSeoUrls);
  const [prevPathname, setPrevPathname] = useState(pathname);
  const { type: pageType } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const qsPostType = prefix && PostCardPrefixAsPostType[prefix];
  const postTypeName = qsPostType && PostTypeName[qsPostType].toLowerCase();

  /**
   * Запрашивает список, если еще ни разу не запрашивали или изменился pathname
   */
  useEffect(() => {
    if (
      (status !== ERequestStatus.Initial && pathname === prevPathname) ||
      pageType !== EPageType.PostCard ||
      !qsPostType ||
      ![EPostType.Articles, EPostType.Blogs, EPostType.Questions].includes(qsPostType)
    ) {
      return;
    }

    void dispatch(getSeoUrls({ postId: qsPostId }));
    setPrevPathname(pathname);
  }, [dispatch, pageType, pathname, prevPathname, qsPostId, qsPostType, status]);

  /**
   * Сбрасывает список при размонтировании
   */
  useEffect(() => {
    return () => {
      if (items.length) {
        dispatch(resetSeoUrls());
      }
    };
  }, [dispatch, items.length]);

  if (!items.length) {
    return null;
  }

  return <SeoUrls items={items} title={postTypeName && `Ещё ${postTypeName}`} />;
};
