import React from 'react';

import { Skeleton } from '../Skeleton';
import * as s from './PostParents.css';

/**
 * Скелетон списка ссылок на родителей поста: категории или рубрики
 */
export const PostParentsSkeleton = () => {
  return (
    <div className={s['wrapper']}>
      <Skeleton width={120} />
      <Skeleton width={100} />
    </div>
  );
};
