import React from 'react';

import * as s from './PostCardBodyLayout.css';

/**
 * Декоратор для компонентов body в Layout'е
 */
export const PostCardBodyLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);
