import * as React from 'react';

import { Skeleton } from '../../../../components/Skeleton';
import * as s from './Counts.css';

/**
 * Скелетон для каунтов лайков и комментов
 */
export const CountsSkeleton = () => {
  return (
    <div className={s['wrapper']}>
      <div className={s['likes-wrapper']}>
        <Skeleton width={'50px'} />
        <Skeleton width={'50px'} />
      </div>

      <Skeleton width={'80px'} />
    </div>
  );
};
